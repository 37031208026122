import React, { Component } from "react";
import "./CeoMessage.css";
import Header from "./Header";
import CEOImg from "../images/ceo-2.jpg";
import Footer from "../footer";
import * as firebase from "firebase";

class CeoMessage extends Component {
  state = {
    ceo: []
  };
  componentDidMount = () => {
    const ceoRef = firebase.database().ref("/ceo");
    ceoRef.on("value", snap => {
      if (snap.val) {
        this.setState({ ceo: snap.val() });
      }
    });
  };
  render() {
    const { languageKeywords, selectedLanguage } = this.props.language;
    return (
      <div classNameName="Header">
        <Header title={"CEO Words / Founders vision"} IMG={CEOImg} />
        <section className="blogs section-padding">
          <div className="container">
            <div className="row">
              <div className=" offset-md-1 col-md-10  offset-md-1 ">
                <div
                  className="posts"
                  style={{
                    textAlign: "justify",
                    fontStyle: "italic !important"
                  }}
                >
                  <h5 style={{ fontWeight: "bold", fontStyle: "italic" }}>
                    {" "}
                    {
                      languageKeywords.ceo_message[selectedLanguage.language]
                    }{" "}
                  </h5>{" "}
                  <br />
                  <br />
                  <p
                    style={{
                      fontSize: "19px",
                      paddingLeft: "70px",
                      fontStyle: "italic",
                      width: "85"
                    }}
                  >{`"  ${this.state.ceo.message}"`}</p>
                  <br />
                  <br />
                  {/*    <!-- As Blockchain Technology clearly makes strides into an increasing number of industries, our perception of strong values is gradually shifting. Transparency and independence are being redefined as efficiency pushes bounderies. Join us today in building tomorrow’s ecosystem.

                    <br><br>

                As we push through the 21rst century, essential values are being redefined. Trust in mathematics is gaining ground on traditional human trust. Blockchain technology will do to wealth and transactions what internet did to time and space. I would strongly recommend embracing Blockchain as it is proving its true potential to empower our civilisation. --> */}
                  <h5
                    style={{
                      height: "200px",
                      width: "200px",
                      float: "right",
                      fontSize: "22px",
                      fontStyle: "italic"
                    }}
                    className="images-responsive pull-right"
                  >
                    {this.state.ceo.name}
                    <br />
                    <p>Ceo Venturex Labs</p>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default CeoMessage;
