import React, { Component } from 'react';
import Header from './Header';
import ServicesImg from '../images/Industries-1.jpg';
import Footer from '../footer';
import Services from '../Services';
import Navbar from '../Navbar';

class ServicesContainer extends Component {
    render() {
        return (
            <div >
                <Header title={"Services"} IMG={ServicesImg} />
                <Services language={this.props.language} />

            </div>
        )
    }
}

export default ServicesContainer;
