import React, { Component } from "react";

class Comments extends Component {
  convertToArray(Objects) {
    const arrayResult = Object.keys(Objects).map(key => {
      return { id: key, data: Objects[key] };
    });
    return arrayResult;
  }
  render() {
    const { languageKeywords, selectedLanguage } = this.props.language;

    if (this.props.comment) {
      var allComments = this.props.comment.reverse();
    }
    console.log(allComments);

    if (allComments.length !== 0) {
      var Comm = allComments.map(val => (
        <div className="comment-box" key={val.id}>
          <div className="author-thumb">
            {/* <img src="images/blog/01.html" alt="image" /> */}
          </div>
          <div className="comment-info">
            <h6>
              <a href="#0">{val.data.name}</a>
            </h6>
            <p>{val.data.comment}</p>
            <span className="time">{val.data.date}</span>
          </div>
        </div>
      ));
    } else {
      var Comm = (
        <p
          style={{
            color: "red",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center"
          }}
        >
          There is no comment!!!
        </p>
      );
    }
    return (
      <div className="comments">
        <div className="comments-area mb-80">
          <div className="title-g mb-50 mb-4" id="comments">
            <h3>{languageKeywords.comments[selectedLanguage.language]}</h3>
          </div>
          {Comm}
        </div>
      </div>
    );
  }
}

export default Comments;
