import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import * as firebase from "firebase";
import "./Hero.css";
// import doTranslate from "./helpers/googleTranslateHelper";

class Hero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      about: "",
      mission: [],
      languageChangeCount: props.language.languageChangeCount
    };
  }
  componentDidMount = () => {
    const aboutRef = firebase.database().ref("/about");
    aboutRef.on("value", snap => {
      if (snap.val()) {
        this.setState({ about: snap.val() }, cb => {
          this.translationAbout();
        });
      }
    });
    const missionRef = firebase.database().ref("/mission");
    missionRef.on("value", snap => {
      if (snap.val()) {
        this.setState({ mission: snap.val() }, cb => {
          this.translationMission();
        });
      }
    });
  };
  componentDidUpdate() {
    if (
      this.state.languageChangeCount != this.props.language.languageChangeCount
    ) {
      console.log("Language Change Detected");
      this.setState(
        {
          languageChangeCount: this.props.language.languageChangeCount
        },
        cb => {
          this.translationAbout();
          this.translationMission();
        }
      );
    }
  }
  translationAbout() {
    let that = this;
    let about = this.state.about;

    // doTranslate(
    //   about.paragraph,
    //   that.props.language.selectedLanguage.language
    // ).then(trans => {
    //   about.paragraph = trans;
    //   that.setState({ about });
    // });

    // doTranslate(
    //   about.hero_paragraph,
    //   that.props.language.selectedLanguage.language
    // ).then(trans => {
    //   about.hero_paragraph = trans;
    //   that.setState({ about });
    // });
  }
  translationMission() {
    let that = this;
    let mission = this.state.mission;

    // doTranslate(
    //   mission.paragraph,
    //   that.props.language.selectedLanguage.language
    // ).then(trans => {
    //   mission.paragraph = trans;
    //   that.setState({ mission });
    // });
  }
  render() {
    const { languageKeywords, selectedLanguage } = this.props.language;
    return (
      <Fragment id="about">
        <section
          href="/#about"
          id="about"
          className="hero section-padding pb-0"
        >
          <div className="container">
            <div className="row" id="about">
              <div
                className="offset-lg-2 col-lg-8 text-center mb-80"
                style={{ textAlign: "justify" }}
              >
                <h4 className="extra-text">
                  Harnessing <span>Blockchain Technology</span> in all
                  Simplicity
                </h4>

                <p style={{ textAlign: "justify" }}>
                  {this.state.about.hero_paragraph}
                </p>
              </div>
              <div className="col-lg-12" style={{ textAlign: "justify" }}>
                <div className="mission">
                  <div className="section-head mb-30">
                    <h4>{languageKeywords.about[selectedLanguage.language]}</h4>
                  </div>
                  <p>{this.state.about.paragraph}</p>
                </div>
              </div>
              <div className="col-lg-7" style={{ textAlign: "justify" }}>
                <div className="mission">
                  <div className="section-head mb-30">
                    <h4>
                      <span>Our</span>
                      <br></br>
                      {languageKeywords.mission[selectedLanguage.language]}
                    </h4>
                  </div>

                  <p>{this.state.mission.paragraph}</p>
                  <div className="pull-left">
                    <Link
                      to="/ceomessage"
                      id="services"
                      target="_blank"
                      className="btn btn-primary"
                      style={{
                        backgroundColor: "#8e0505",
                        borderColor: "#8e0505"
                      }}
                    >
                      <span>
                        {
                          languageKeywords.ceo_message[
                            selectedLanguage.language
                          ]
                        }
                      </span>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-5">
                <div className="skills">
                  <img
                    src={this.state.mission.image}
                    loading="lazy"
                    className="images-thumbnail img-responsive"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default Hero;
