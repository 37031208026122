import React, { Component } from "react";
import * as firebase from "firebase";

class RecentPosts extends Component {
  state = {
    search: "",
    blogs: []
  };
  componentDidMount = () => {
    const blogsRef = firebase.database().ref("/blogs");
    blogsRef.on("value", snap => {
      if (snap.val()) {
        this.setState({
          blogs: this.convertToArray(snap.val())
        });
      }
    });
  };
  convertToArray(Objects) {
    const arrayResult = Object.keys(Objects).map(key => {
      return { id: key, data: Objects[key] };
    });
    return arrayResult;
  }
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    const { languageKeywords, selectedLanguage } = this.props.language;
    return (
      <div className="RecentPosts">
        <div className="side-bar">
          <div className="widget search">
            <form>
              <input
                id="myInput"
                value={this.state.search}
                name="search"
                type="search"
                placeholder={
                  languageKeywords.search_blog_title[selectedLanguage.language]
                }
                onChange={this.handleChange}
              />
              <button type="submit">
                <i className="fa fa-search" aria-hidden="true"></i>
              </button>
            </form>
          </div>

          <div className="widget">
            <div className="widget-title">
              <h6>
                {languageKeywords.recent_posts[selectedLanguage.language]}
              </h6>
            </div>
            <ul id="myTable">
              <li>
                <a href="#">Coin Offering in the Years to Come?</a> <hr />{" "}
              </li>
              <li>
                <a href="#">Is Bitcoin the end of Fiat Currency?</a> <hr />{" "}
              </li>
              <li>
                <a href="#">
                  Crypto Markets Hold Weekly Gains Amidst Little Action
                </a>{" "}
                <hr />{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default RecentPosts;
