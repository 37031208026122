import React from "react";
import BigData from "./svgicons/BigData";
import IBM from "./svgicons/IBM";
import ERP from "./svgicons/ERP";
import Payment from "./svgicons/Payment";
import SocialTrading from "./svgicons/SocialTrading";
import CardIntegration from "./svgicons/CardIntegration";
import P2PLoan from "./svgicons/P2PLoan";
import MarginTrading from "./svgicons/MarginTrading";
import SmartContracts from "./svgicons/SmartContracts";
import ICO from "./svgicons/ICO";
import ATM from "./svgicons/ATM";
import Quorum from "./svgicons/Quorum";
import AI from "./svgicons/AI";
import TaxiDispatch from "./svgicons/TaxiDispatch";

const SvgIcon = ({ name, fill, width, height, bg }) => {
  if (name === "Big Data") {
    return <BigData fill={fill} width={width} height={height} bg={bg} />;
  } else if (name === "IBM") {
    return <IBM fill={fill} width={width} height={height} bg={bg} />;
  } else if (name === "ERP") {
    return <ERP fill={fill} width={width} height={height} bg={bg} />;
  } else if (name === "Payment") {
    return <Payment fill={fill} width={width} height={height} bg={bg} />;
  } else if (name === "Social Trading") {
    return <SocialTrading fill={fill} width={width} height={height} bg={bg} />;
  } else if (name === "Card Integration") {
    return (
      <CardIntegration fill={fill} width={width} height={height} bg={bg} />
    );
  } else if (name === "P2P Loan") {
    return <P2PLoan fill={fill} width={width} height={height} bg={bg} />;
  } else if (name === "Margin Trading") {
    return <MarginTrading fill={fill} width={width} height={height} bg={bg} />;
  } else if (name === "Smart Contracts") {
    return <SmartContracts fill={fill} width={width} height={height} bg={bg} />;
  } else if (name === "ICO Development") {
    return <ICO fill={fill} width={width} height={height} bg={bg} />;
  } else if (name === "ATM Machines") {
    return <ATM fill={fill} width={width} height={height} bg={bg} />;
  } else if (name === "Quorum Development") {
    return <Quorum fill={fill} width={width} height={height} bg={bg} />;
  } else if (name === "AI") {
    return <AI fill={fill} width={width} height={height} bg={bg} />;
  } else if (name === "Taxi Dispatch") {
    return <TaxiDispatch fill={fill} width={width} height={height} bg={bg} />;
  }
};

export default SvgIcon;
