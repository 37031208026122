import React from "react";

export default ({ width, height, fill, bg }) => {
  return (
    <svg
      style={{ backgroundColor: bg }}
      fill={fill}
      width={width}
      height={height}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 454.44 454.35"
    >
      <g>
        <path
          class="st0"
          d="M144.85,352.12h-24.57c-2.9,0-5.27-2.37-5.27-5.27v-47.98c0-2.9,2.37-5.27,5.27-5.27h24.57
		c2.9,0,5.27,2.37,5.27,5.27v47.98C150.11,349.75,147.74,352.12,144.85,352.12z"
        />
        <path
          class="st0"
          d="M201.11,352.12h-24.57c-2.9,0-5.27-2.37-5.27-5.27v-89.39c0-2.9,2.37-5.27,5.27-5.27h24.57
		c2.9,0,5.27,2.37,5.27,5.27v89.39C206.37,349.75,204,352.12,201.11,352.12z"
        />
        <path
          class="st0"
          d="M257.37,352.12h-24.57c-2.9,0-5.27-2.37-5.27-5.27V216.06c0-2.9,2.37-5.27,5.27-5.27h24.57
		c2.9,0,5.27,2.37,5.27,5.27v130.79C262.64,349.75,260.27,352.12,257.37,352.12z"
        />
        <path
          class="st0"
          d="M312.58,352.12h-24.57c-2.9,0-5.27-2.37-5.27-5.27v-174c0-2.9,2.37-5.27,5.27-5.27h24.57
		c2.9,0,5.27,2.37,5.27,5.27v174C317.85,349.75,315.48,352.12,312.58,352.12z"
        />
        <path
          class="st0"
          d="M369.3,352.12h-24.57c-2.9,0-5.27-2.37-5.27-5.27V131.44c0-2.9,2.37-5.27,5.27-5.27h24.57
		c2.9,0,5.27,2.37,5.27,5.27v215.41C374.56,349.75,372.19,352.12,369.3,352.12z"
        />
      </g>
    </svg>
  );
};
