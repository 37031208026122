import React from "react";

export default ({ width, height, fill, bg }) => {
  return (
    <svg
      style={{ backgroundColor: bg }}
      fill={fill}
      width={width}
      height={height}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 454.44 454.35"
    >
      <g>
        <path
          class="st0"
          d="M298.51,219.4c-1.74-15.8-8.04-29.6-18.97-41.24c-11.26-12-25.1-19.29-41.39-21.82
		c-2.44-0.38-4.92-0.5-7.39-0.76c-0.26-0.03-0.52-0.13-0.78-0.2h-5.52c-1.46,0.15-2.91,0.31-4.37,0.46
		c-16.22,1.68-30.3,8.08-42.16,19.25c-10.65,10.03-17.62,22.24-20.72,36.54c-0.89,4.12-1.13,8.38-1.68,12.57
		c-0.04,0.34-0.13,0.68-0.2,1.02v1.46c0.69-0.06,1.39-0.1,2.09-0.1c2.1,0,4.12,0.26,6.03,0.75c0-0.04,0-0.07,0-0.11
		c-0.08-35.61,29.41-64.41,64.97-63.75c33.44,0.62,61.59,27.57,62.51,62.07C293.13,223.13,295.69,221.05,298.51,219.4z"
        />
        <path
          class="st0"
          d="M227.7,173.64c-14.29-0.01-28.27,5.6-38.41,15.71c-4.84,4.82-8.73,10.57-11.4,16.85
		c-2.58,6.07-4.92,13.88-4.21,20.55c-0.18-1.69,3.1-2.56,4.25-2.93c7.15-2.36,14.14-5.46,21.64-6.55c3.77-0.55,6.93-0.43,10.51,0.87
		c3.28,1.2,6.32,3.05,8.81,5.51c5.66-7.29,14.68-12.12,23.94-12.12c5.61,0,11,2.61,15.68,5.48c4.55,2.79,8.82,6.13,13.58,8.55
		c1.94,0.99,8.79,2.54,8.6,5.34c0.08-1.2,0.14-2.41,0.14-3.63C280.82,197.82,256.87,173.65,227.7,173.64z"
        />
      </g>
      <path
        class="st0"
        d="M93.12,280.51c2.27-5.54,4.61-11.05,6.81-16.62c6.53-16.52,13-33.06,19.5-49.59c1.8-4.59,4.39-5.7,9.05-3.96
	c5.05,1.89,10.12,3.74,15.16,5.67c3.88,1.49,4.99,3.96,3.48,7.85c-8.52,21.91-17.06,43.81-25.61,65.71
	c-1.71,4.36-4.26,5.45-8.68,3.84c-4.66-1.7-9.4-3.23-13.95-5.2c-2.11-0.91-3.85-2.67-5.76-4.05
	C93.12,282.95,93.12,281.73,93.12,280.51z"
      />
      <path
        class="st0"
        d="M171.79,322.87c-1.16-6.1-3.42-11.17-9.43-13.4c-5.89-2.18-10.95-0.42-15.65,3.86
	c-3.05-3.02-6.05-5.9-8.93-8.89c-3.57-3.71-7.09-7.47-10.48-11.35c-0.63-0.72-1.03-2.28-0.7-3.12c8-20.44,16.11-40.84,24.21-61.25
	c0.06-0.15,0.18-0.28,0.19-0.29c2.43,1.49,4.77,3.11,7.28,4.43c5,2.64,10.32,2.71,15.48,0.81c5.37-1.98,10.64-4.3,15.78-6.83
	c4.95-2.43,9.78-5.22,15.47-5.02c2.68,0.09,5.33,1,7.99,1.53c0.01,0.32,0.01,0.63,0.02,0.95c-0.73,0.54-1.45,1.09-2.2,1.6
	c-12.55,8.54-25.13,17.04-37.66,25.61c-5.76,3.93-7.99,9.58-7.15,16.34c1.02,8.17,7.99,13.06,16.28,11.49
	c2.63-0.5,5.29-1.3,7.67-2.48c13.12-6.49,26.18-13.1,39.22-19.77c1.8-0.92,2.91-0.98,4.45,0.63c22.49,23.68,45.07,47.27,67.53,70.96
	c0.74,0.78,1.02,2.61,0.68,3.68c-2.15,6.84-10.89,8.48-16.14,3c-5.56-5.8-10.96-11.75-16.42-17.63c-2.79-3.01-5.62-5.98-8.36-9.04
	c-1.39-1.56-2.77-2.03-4.48-0.55c-1.77,1.53-2.07,3.6-0.52,5.27c4.53,4.85,9.14,9.62,13.72,14.42c2.35,2.47,4.79,4.85,7.01,7.43
	c3.07,3.58,3.62,7.68,1.7,11.97c-2.85,6.36-10.57,7.61-15.37,2.55c-7.81-8.24-15.6-16.49-23.37-24.76c-1.32-1.4-2.54-2.87-4.55-1.06
	c-1.6,1.44-1.61,3.74,0.07,5.58c5.82,6.39,11.7,12.73,17.49,19.14c3.93,4.35,4.21,10.21,0.83,14.62c-2.3,3.01-6.15,4.13-9.59,2.84
	c-1.38-0.52-1.96-1.07-1.56-2.87c2.19-9.85-3.55-18.12-13.62-19.59c1.61-6.63,0.96-12.91-4.41-17.66
	c-5.48-4.85-11.56-4.51-17.53-0.96c-0.59-2.13-0.93-4.21-1.72-6.1c-3.94-9.39-15-11.94-22.76-5.29
	C178.83,316.63,175.46,319.64,171.79,322.87z"
      />
      <path
        class="st0"
        d="M296.19,230.93c3.31,6.16,6.53,12.24,9.83,18.27c7.82,14.3,15.65,28.6,23.55,42.86
	c0.84,1.51,0.83,2.59-0.05,4.04c-6.7,11.01-16.11,19.37-26.28,27.36c-4.55-4.82-8.95-9.51-13.39-14.17
	c-17.92-18.86-35.85-37.7-53.77-56.56c-2.13-2.24-4.3-2.68-7.08-1.28c-13.16,6.62-26.27,13.31-39.47,19.86
	c-2.54,1.26-5.3,2.43-8.07,2.82c-7.82,1.1-12.53-4.82-9.83-12.27c0.71-1.95,2.03-3.98,3.64-5.26c16.55-13.2,34.41-24.39,53.1-34.31
	c11.61-6.16,22.82-4.98,33.85,1.35c5.06,2.9,10.04,5.96,15.09,8.9c5.72,3.33,11.36,2.49,16.91-0.51
	C294.82,231.69,295.42,231.35,296.19,230.93z"
      />
      <path
        class="st0"
        d="M320.32,210.83c3.26,0.01,4.82,1.41,6,3.58c8.22,15.09,16.47,30.15,24.71,45.22
	c3.05,5.58,6.13,11.16,9.17,16.75c2.08,3.85,1.3,6.62-2.49,8.75c-4.79,2.7-9.59,5.36-14.41,7.99c-3.62,1.98-6.56,1.14-8.55-2.49
	c-11.32-20.74-22.62-41.49-33.91-62.26c-1.93-3.56-1.13-6.44,2.34-8.42c4.85-2.76,9.73-5.47,14.63-8.15
	C318.76,211.28,319.87,211,320.32,210.83z"
      />
      <path
        class="st0"
        d="M215.46,327.71c3.83,0.48,6.47,2.28,8.13,5.43c1.87,3.58,1.75,7.24-0.35,10.7c-0.71,1.16-1.74,2.18-2.77,3.09
	c-6.07,5.38-12.34,10.53-18.22,16.1c-5.25,4.97-10.88,3.62-13.73,0.68c-3.95-4.06-4.26-11.17,0.02-15.13
	c7.03-6.51,14.38-12.68,21.74-18.81C211.62,328.65,213.71,328.38,215.46,327.71z"
      />
      <path
        class="st0"
        d="M191.19,315.54c5.42,0.19,8.23,2.28,9.76,5.98c1.72,4.16,1.2,8.3-2.04,11.38
	c-5.62,5.34-11.53,10.39-17.49,15.35c-3.13,2.61-7.46,2.41-10.69,0.03c-3.48-2.56-5.09-7.72-3.39-11.97c0.72-1.8,1.89-3.6,3.31-4.89
	c5.04-4.56,10.19-9.01,15.49-13.27C187.87,316.75,190.16,316.05,191.19,315.54z"
      />
      <path
        class="st0"
        d="M210.44,368.68c-0.02-3.04,1.12-5.61,3.23-7.75c2.69-2.74,5.42-5.45,8.14-8.15c3.52-3.49,7.83-4.25,11.64-2.07
	c3.74,2.14,5.71,7.15,4.56,11.6c-0.11,0.42-0.18,0.88-0.4,1.24c-3.72,6.14-8.68,11.35-14.91,14.61
	C216.79,381.25,210.44,375.91,210.44,368.68z"
      />
      <path
        class="st0"
        d="M144.35,326.74c-0.02-2.72,1.27-5.47,3.8-7.64c0.99-0.85,2.04-1.63,3.08-2.43c4.19-3.21,8.37-3.39,11.97-0.53
	c3.43,2.73,4.87,8.27,2.92,12.35c-2.07,4.31-5.8,7.11-10.17,8.45C149.91,338.79,144.31,333.9,144.35,326.74z"
      />
    </svg>
  );
};
