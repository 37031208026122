import React from "react";

export default ({ width, height, fill, bg }) => {
  return (
    <svg
      style={{ backgroundColor: bg }}
      fill={fill}
      width={width}
      height={height}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 454.44 454.35"
    >
      <g>
        <path
          class="st0"
          d="M31.67,64.08 M32.17,390.27 M175.97,347.3c54.98,1.58,101.3-45.15,101.94-100.31
   c0.66-56.72-46.62-103.39-102.08-103.15c-54.45,0.24-101.85,43.58-101.94,101.73C73.79,304.2,121.95,348.79,175.97,347.3z
    M176.67,101.88c9.3-0.06,18.42,0.85,27.4,2.68c104.75,21.33,151.06,141.03,87.88,227.22c-1.76,2.4-2.09,3.8,0.33,6.03
   c10.28,9.48,20.46,19.08,30.44,28.88c2.61,2.56,3.73,1.65,5.7-0.55c46.88-52.21,56.52-132.77,23.17-194.28
   C314.44,103.3,242.65,68.13,165.78,82.34c-51.85,9.58-90.71,38.68-117.74,83.85c-2.4,4.02,0.07,5.51,2.42,7.08
   c3.32,2.22,3.24-1.76,4.04-3C83.28,126.01,124.27,103.75,176.67,101.88z M422.77,254.35"
        />
        <path
          class="st0"
          d="M175.97,347.3c-54.02,1.49-102.18-43.1-102.08-101.73c0.1-58.14,47.49-101.49,101.94-101.73
   c55.46-0.24,102.74,46.43,102.08,103.15C277.27,302.15,230.95,348.88,175.97,347.3z"
        />
        <path
          class="st0"
          d="M176.67,101.88c-52.4,1.87-93.39,24.14-122.18,68.4c-0.81,1.24-0.72,5.22-4.04,3
   c-2.35-1.57-4.82-3.07-2.42-7.08c27.03-45.17,65.9-74.27,117.74-83.85c76.87-14.21,148.66,20.96,185.82,89.52
   c33.34,61.51,23.7,142.07-23.17,194.28c-1.98,2.2-3.09,3.11-5.7,0.55c-9.98-9.79-20.16-19.4-30.44-28.88
   c-2.42-2.23-2.09-3.63-0.33-6.03c63.17-86.19,16.87-205.89-87.88-227.22C195.09,102.73,185.97,101.81,176.67,101.88z"
        />
      </g>
    </svg>
  );
};
