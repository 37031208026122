import React, { Component } from 'react';
import IndustriesImg from '../images/Industries-1.jpg';
import Header from './Header';
import Footer from '../footer';
import IndustriesPage from './IndustriesPage';
import FeaturedInsight from './FeaturedInsight';
import Navbar from '../Navbar';

class IndustriesContainer extends Component {
    render() {
        const { languageKeywords, selectedLanguage } = this.props.language;
        return (
            <div className='IndustriesContainer'>
                {/* <Navbar/> */}
                <Header title={languageKeywords.industries[selectedLanguage.language]} IMG={IndustriesImg} language={this.props.language} />
                <IndustriesPage language={this.props.language} />
                <FeaturedInsight language={this.props.language} />
            </div>
        )
    }
}

export default IndustriesContainer;