import React, { Component } from 'react';
import './Exchange.css';

class Exchange extends Component{
    render() {
        return (
            <div className='Exchange'>
                 <div className="tab-pane fade in active show">
                    <div className="row" style={{marginTop:"20px"}}>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="card">
                                <div className="item-img">

                                    <img src="images/ICO-B-1.jpg" alt="image" />
                                    <a href="images/ICO-B-1.jpg">
                                        <div className="item-img-overlay valign">
                                            <div className="overlay-info full-width vertical-center">
                                                <h6 style={{textAlign:"center"}}>Exchange</h6>
                                                <p style={{textAlign:"center"}}>http://www.venturexlabs.com/ </p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                         <div className="col-md-4 col-sm-4 col-xs-12">
                          <div className="card">
                              <div className="item-img">
                                
                                  <img src="images/ICO-B-1.jpg" alt="image" />
                                  <a href="images/ICO-B-1.jpg">
                                      <div className="item-img-overlay valign">
                                          <div className="overlay-info full-width vertical-center">
                                              <h6 style={{textAlign:"center"}}>Ewallet</h6>
                                              <p style={{textAlign:"center"}}>http://www.venturexlabs.com/ </p>
                                          </div>
                                      </div>
                                  </a>
                              </div>
                          </div>
                        </div>
                          
                    </div>
                </div>
                
            </div>
        )
    }
}

export default Exchange;