import React from "react";

export default ({ width, height, fill, bg }) => {
  return (
    <svg
      style={{ backgroundColor: bg }}
      fill={fill}
      width={width}
      height={height}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 454.44 454.35"
    >
      <path
        class="st0"
        d="M364.27,250.87v-63.39c10.27-2.21,17.99-11.36,17.99-22.28c0-18.04-20.01-28.81-35.03-19.2l-45.31-45.31
	c9.61-15.02-1.17-35.03-19.2-35.03c-22.22,0-31.34,28.8-12.93,41.54l-20.3,49.17l-34.56-34.56l35.89-14.92
	c2.45-1.02,3.61-3.82,2.59-6.27c-1.02-2.45-3.82-3.61-6.27-2.59l-39.55,16.44l-13.68-13.68c9.71-14.99-1.02-35.14-19.14-35.14
	c-18.04,0-28.81,20.01-19.2,35.03L110.26,146c-15.02-9.61-35.03,1.17-35.03,19.2c0,14.2,12.9,24.98,26.9,22.41l13.12,31.56
	l-13.12,31.56c-13.98-2.56-26.9,8.18-26.9,22.41c0,18.04,20.01,28.81,35.03,19.2l45.31,45.31c-9.61,15.02,1.17,35.03,19.2,35.03
	c10.92,0,20.06-7.72,22.27-17.99h63.39c2.21,10.27,11.36,17.99,22.27,17.99c12.56,0,22.79-10.22,22.79-22.79
	s-10.22-22.79-22.79-22.79c-10.92,0-20.06,7.72-22.27,17.99h-61.62l141.93-58.99c12.82,18.44,41.5,9.07,41.5-12.97
	C382.26,262.22,374.54,253.08,364.27,250.87z M337.05,169.27l-13.28,5.48l-27.47-66.11l44.1,44.1
	C337.3,157.48,335.98,163.32,337.05,169.27L337.05,169.27z M340.72,178.13c3.24,4.69,8.2,8.11,13.95,9.34v61.62l-27.22-65.49
	L340.72,178.13z M345.76,252.64l-54.21-54.21l27.04-11.16L345.76,252.64z M372.66,165.2c0,7.27-5.92,13.19-13.19,13.19
	c-7.27,0-13.19-5.92-13.19-13.19s5.92-13.19,13.19-13.19C366.75,152.01,372.66,157.93,372.66,165.2z M282.71,75.25
	c7.27,0,13.19,5.92,13.19,13.19c0,7.27-5.92,13.19-13.19,13.19c-7.27,0-13.19-5.92-13.19-13.19
	C269.52,81.17,275.44,75.25,282.71,75.25z M278.65,110.86c2.72,0.49,5.42,0.5,8.18-0.01l28.08,67.56l-30.71,12.68l-27.37-27.37
	L278.65,110.86z M274.6,195.05l-37.01,15.28l15.28-37.01L274.6,195.05z M170.66,110.85c5.81,1.06,11.64-0.15,16.47-3.27L198,118.46
	l-36.87,15.32L170.66,110.85z M148.57,139l-30.36,12.62l42.98-42.98L148.57,139z M174.77,75.25c7.27,0,13.19,5.92,13.19,13.19
	c0,7.27-5.92,13.19-13.19,13.19s-13.19-5.92-13.19-13.19C161.58,81.17,167.5,75.25,174.77,75.25z M84.83,165.2
	c0-7.27,5.92-13.19,13.19-13.19c7.27,0,13.19,5.92,13.19,13.19s-5.92,13.19-13.19,13.19C90.74,178.4,84.83,172.48,84.83,165.2z
	 M110.99,183.92c7.3-5.07,11.07-13.91,9.43-22.83l22.93-9.53l-22.9,55.11L110.99,183.92z M120.45,231.67l9.58,23.06l-13.26,5.48
	c-1.56-2.26-3.52-4.22-5.77-5.79L120.45,231.67z M120.43,269.08l13.28-5.48l27.47,66.11l-44.11-44.1
	C120.18,280.88,121.51,275.04,120.43,269.08z M84.83,273.14c0-7.27,5.92-13.19,13.19-13.19c7.27,0,13.19,5.92,13.19,13.19
	s-5.92,13.19-13.19,13.19C90.74,286.34,84.83,280.42,84.83,273.14z M174.77,363.09c-7.27,0-13.19-5.92-13.19-13.19
	c0-7.27,5.92-13.19,13.19-13.19s13.19,5.92,13.19,13.19C187.97,357.17,182.05,363.09,174.77,363.09z M282.71,336.71
	c7.27,0,13.19,5.92,13.19,13.19c0,7.27-5.92,13.19-13.19,13.19c-7.27,0-13.19-5.92-13.19-13.19
	C269.52,342.63,275.44,336.71,282.71,336.71z M193.49,336.93c-5.06-7.28-13.89-11.07-22.83-9.43l-28.08-67.56l18.7-7.72
	c2.45-1.01,3.61-3.82,2.6-6.26c-1.01-2.45-3.82-3.61-6.26-2.6l-18.72,7.73l-13.26-31.9l30.27-72.83l49.43-20.54l40.18,40.18
	l-20.45,49.53l-32.62,13.47c-2.45,1.01-3.61,3.82-2.6,6.26c1.02,2.46,3.83,3.61,6.26,2.6l23.79-9.82l-34.22,82.9
	c-1.31,3.16,1.03,6.63,4.43,6.63c1.88,0,3.67-1.12,4.44-2.97l37.87-91.72l49.53-20.45l58.39,58.39c-3.12,4.82-4.34,10.65-3.27,16.47
	L193.49,336.93z M359.47,286.34c-7.27,0-13.19-5.92-13.19-13.19s5.92-13.19,13.19-13.19s13.19,5.92,13.19,13.19
	S366.75,286.34,359.47,286.34z"
      />
      <path
        class="st0"
        d="M175.05,236.15c-2.63,1.08-3.65,3.92-2.68,6.28c1.01,2.46,3.81,3.62,6.26,2.62c2.63-1.08,3.65-3.92,2.68-6.28
	C180.3,236.32,177.5,235.15,175.05,236.15z"
      />
    </svg>
  );
};
