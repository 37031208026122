import React from "react";

export default ({ width, height, fill, bg }) => {
  return (
    <svg
      style={{ backgroundColor: bg }}
      fill={fill}
      width={width}
      height={height}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 454.44 454.35"
    >
      <g>
        <g>
          <g>
            <path
              class="st0"
              d="M316.39,215.28c-1.02-8.2-4.45-14.73-5.89-17.18c1.04-17.06-2.34-29.91-10.06-38.2
				c-6.02-6.46-13.04-8.29-16.61-8.8c-5.44-11.51-14.7-13.73-20.32-13.19c-10.53-9.81-20.27-10.04-26.69-8.44
				c-4.23,1.06-7.55,3.04-9.6,4.54c-2.06-1.5-5.37-3.48-9.6-4.54c-6.42-1.6-16.16-1.37-26.69,8.44
				c-5.63-0.54-14.88,1.68-20.32,13.19c-3.57,0.51-10.6,2.34-16.61,8.8c-7.72,8.29-11.1,21.14-10.06,38.2
				c-1.44,2.45-4.86,8.98-5.89,17.18c-1.16,9.29,0.61,22.4,15.14,33.03c-1.68,9.94-0.09,27.05,15.79,35.46
				c-0.66,3.62-1.1,9.89,2.13,15.25c2.54,4.22,6.73,6.99,12.48,8.26c2.9,4.48,12.9,18.34,25.38,18.34c0.08,0,0.16,0,0.24,0
				c6.69-0.1,12.74-4.05,18.02-11.77c5.29,7.72,11.33,11.68,18.02,11.77c12.57,0.18,22.7-13.84,25.62-18.34
				c5.75-1.27,9.94-4.04,12.48-8.26c3.23-5.36,2.79-11.63,2.13-15.25c15.88-8.41,17.47-25.52,15.79-35.46
				C315.78,237.68,317.56,224.58,316.39,215.28z M295.56,243.97c-1.2,0.8-1.77,2.27-1.42,3.67c0.23,0.93,5.45,22.79-13.98,31.12
				c-1.63,0.7-2.46,2.53-1.89,4.22c0.02,0.07,2.33,7.38-0.78,12.54c-1.68,2.8-4.82,4.6-9.3,5.35c-1,0.17-1.87,0.77-2.39,1.64
				c-2.67,4.55-11.49,16.31-20.32,16.31c-0.05,0-0.1,0-0.15,0c-5.31-0.08-10.37-4.46-15.04-13.03c-0.6-1.1-1.88-1.78-3.13-1.78
				s-2.4,0.68-3,1.78c-4.67,8.56-9.73,12.95-15.04,13.03c-8.84,0.14-17.79-11.72-20.48-16.3c-0.51-0.87-1.39-1.48-2.39-1.64
				c-4.47-0.75-7.6-2.54-9.28-5.32c-3.14-5.18-0.82-12.5-0.8-12.57c0.56-1.68-0.26-3.52-1.89-4.22
				c-19.33-8.28-14.21-30.2-13.98-31.12c0.35-1.4-0.22-2.87-1.42-3.67c-25.46-16.98-9.32-42.06-8.62-43.11
				c0.42-0.63,0.62-1.38,0.57-2.14c-1.11-15.56,1.6-27.02,8.07-34.06c6.22-6.76,13.75-6.94,14.04-6.94c1.4,0,2.65-0.85,3.17-2.15
				c3.82-9.55,9.96-10.91,13.15-10.91c1.03,0,1.75,0.14,1.99,0.2c1.16,0.29,2.39-0.05,3.24-0.9c6.95-6.94,14.08-9.61,21.22-7.93
				c5.46,1.28,9.01,4.72,9.04,4.75c0.64,0.64,1.51,1,2.41,1h0.13c0.9,0,1.77-0.36,2.41-1c0.03-0.03,3.47-3.36,8.78-4.68
				c7.22-1.8,14.45,0.84,21.48,7.87c0.83,0.83,2.06,1.17,3.21,0.91c1.02-0.23,10.12-1.92,15.17,10.71c0.51,1.28,1.77,2.13,3.15,2.15
				c0.32,0.01,7.84,0.18,14.06,6.94c6.47,7.04,9.18,18.5,8.07,34.06c-0.05,0.75,0.14,1.51,0.57,2.14c0.04,0.07,4.35,6.61,5.43,15.28
				C311.03,227.44,306.3,236.81,295.56,243.97z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M284.62,153.23c-0.6-1.79-2.53-2.75-4.32-2.16c-21.23,7.08-21.47,24.82-21.47,25.57
				c0,1.88,1.51,3.39,3.39,3.4c0.01,0,0.02,0,0.02,0c1.86,0,3.39-1.51,3.41-3.38c0.01-0.56,0.38-13.64,16.8-19.11
				C284.25,156.95,285.21,155.02,284.62,153.23z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M300.04,244.74c-1.14-1.5-3.28-1.79-4.78-0.65c-5.56,4.24-10.59,5.75-14.94,4.5
				c-7.75-2.23-11.65-12.43-11.69-12.54c-0.65-1.76-2.62-2.67-4.38-2.01c-1.77,0.65-2.67,2.62-2.02,4.38
				c0.2,0.55,5.1,13.5,16.16,16.71c1.65,0.48,3.33,0.72,5.04,0.72c5.09,0,10.44-2.12,15.97-6.33
				C300.89,248.38,301.18,246.23,300.04,244.74z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M242.33,247.58c-1.33-1.33-3.49-1.33-4.83,0c-1.33,1.33-1.33,3.49,0,4.83c11.08,11.08,8.78,21.79,8.67,22.3
				c-0.44,1.82,0.67,3.66,2.49,4.12c0.28,0.07,0.56,0.1,0.83,0.1c1.53,0,2.92-1.04,3.31-2.59
				C252.94,275.74,256.29,261.55,242.33,247.58z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M295.29,215.59c-0.64-1.77-2.6-2.68-4.37-2.05c-0.69,0.25-16.97,5.94-25.61-5.57
				c-4.49-5.98-6.42-11.09-7.97-15.2c-1.9-5.04-3.7-9.81-9.67-10.3c-1.86-0.16-3.53,1.24-3.68,3.12c-0.15,1.88,1.24,3.53,3.12,3.68
				c1.43,0.12,1.97,0.94,3.84,5.9c1.61,4.28,3.82,10.14,8.9,16.89c5.95,7.92,14.25,9.9,21.09,9.9c6.62,0,11.88-1.86,12.32-2.02
				C295.02,219.32,295.93,217.36,295.29,215.59z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M227.16,134.95c-1.88,0-3.41,1.53-3.41,3.41v169.05c0,1.88,1.53,3.41,3.41,3.41s3.41-1.53,3.41-3.41V138.36
				C230.57,136.48,229.04,134.95,227.16,134.95z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M252.11,164.35c-0.03-1.88-1.52-3.41-3.46-3.36c-1.89,0.03-3.39,1.58-3.36,3.46
				c0,0.21,0.19,21.5-10.45,34.99c-1.17,1.48-0.91,3.63,0.57,4.79c0.63,0.49,1.37,0.73,2.11,0.73c1.01,0,2.01-0.44,2.68-1.3
				C252.32,188.29,252.13,165.32,252.11,164.35z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M259.05,268.91c-15.05,0-22.08,20.59-22.38,21.47c-0.6,1.79,0.37,3.71,2.16,4.31
				c0.36,0.12,0.72,0.18,1.08,0.18c1.43,0,2.76-0.9,3.24-2.33c1.57-4.67,7.49-16.81,15.9-16.81c1.88,0,3.41-1.53,3.41-3.41
				C262.47,270.44,260.94,268.91,259.05,268.91z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M262.9,137.38c-1.51-1.13-3.65-0.81-4.77,0.7l-8.19,11c-1.13,1.51-0.81,3.65,0.7,4.78
				c0.61,0.46,1.32,0.68,2.03,0.68c1.04,0,2.07-0.47,2.74-1.38l8.19-11C264.72,140.64,264.41,138.5,262.9,137.38z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M193.02,159.44c-7.53,0-13.65,6.13-13.65,13.65s6.13,13.65,13.65,13.65s13.65-6.13,13.65-13.65
				S200.55,159.44,193.02,159.44z M193.02,179.92c-3.76,0-6.83-3.06-6.83-6.83s3.06-6.83,6.83-6.83s6.83,3.06,6.83,6.83
				C199.84,176.85,196.78,179.92,193.02,179.92z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M169.12,203.81c-7.53,0-13.65,6.13-13.65,13.65s6.13,13.65,13.65,13.65s13.65-6.13,13.65-13.65
				C182.78,209.93,176.65,203.81,169.12,203.81z M169.12,224.29c-3.76,0-6.83-3.06-6.83-6.83c0-3.76,3.06-6.83,6.83-6.83
				s6.83,3.06,6.83,6.83C175.95,221.23,172.89,224.29,169.12,224.29z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M203.26,234.53c-7.53,0-13.65,6.13-13.65,13.65s6.13,13.65,13.65,13.65s13.65-6.13,13.65-13.65
				S210.79,234.53,203.26,234.53z M203.26,255.01c-3.76,0-6.83-3.06-6.83-6.83c0-3.76,3.06-6.83,6.83-6.83
				c3.76,0,6.83,3.06,6.83,6.83C210.08,251.95,207.02,255.01,203.26,255.01z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M203.26,272.08c-7.53,0-13.65,6.13-13.65,13.65c0,7.53,6.13,13.65,13.65,13.65s13.65-6.13,13.65-13.65
				C216.91,278.2,210.79,272.08,203.26,272.08z M203.26,292.56c-3.76,0-6.83-3.06-6.83-6.83c0-3.76,3.06-6.83,6.83-6.83
				c3.76,0,6.83,3.06,6.83,6.83C210.08,289.49,207.02,292.56,203.26,292.56z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M193.02,179.92c-1.88,0-3.41,1.53-3.41,3.41v34.13c0,1.88,1.53,3.41,3.41,3.41c1.89,0,3.41-1.53,3.41-3.41
				v-34.13C196.43,181.45,194.9,179.92,193.02,179.92z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M203.26,214.05h-23.89c-1.88,0-3.41,1.53-3.41,3.41s1.53,3.41,3.41,3.41h23.89c1.89,0,3.41-1.53,3.41-3.41
				C206.67,215.58,205.14,214.05,203.26,214.05z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M203.26,214.05c-1.88,0-3.41,1.53-3.41,3.41v20.48c0,1.88,1.53,3.41,3.41,3.41c1.89,0,3.41-1.53,3.41-3.41
				v-20.48C206.67,215.58,205.14,214.05,203.26,214.05z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M203.26,255.01c-1.88,0-3.41,1.53-3.41,3.41v17.07c0,1.88,1.53,3.41,3.41,3.41c1.89,0,3.41-1.53,3.41-3.41
				v-17.07C206.67,256.54,205.14,255.01,203.26,255.01z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
