import React, { Component } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
import BG from "./images/footer.png";
import Icofont from "react-icofont";
import Subscriber from "./Subscriber";
import "./Footer.css";
import { HashLink } from "react-router-hash-link";

class Footer extends Component {
  render() {
    return (
      <div>
        <div
          className="FooterForDesktop"
          style={{
            backgroundImage: `url(${BG})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: "#041319",
            paddingTop: "2%",
            color: "white",
            fontWeight: "100",
            fontSize: "11.5px",
            lineHeight: "1.6"
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-2">
                <Link to="/">
                  <img
                    src="images/vxlabs-logo.png"
                    className="Footer-img"
                    alt="logo"
                  />
                </Link>
              </div>
              <div className="col-md-2">
                <br /> <br />
                <h6>INDUSTRIES</h6>
              </div>
              <div className="col-md-2">
                <br /> <br />
                <h6>SOLUTIONS</h6>
              </div>
              <div className="col-md-2">
                <br /> <br />
                <h6>TECHNOLOGIES</h6>
              </div>
              <div className="col-md-2">
                <br /> <br />
                <h6>COMPANY</h6>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-2">
                <ul
                  className="list-unstyled quick-links"
                  style={{ color: "gray" }}
                >
                  <li>FINTECH</li>
                  <li>Crypto Assets</li>
                  <li>Insurance</li>
                  <li>Public Services</li>
                  <li>Data Analytics</li>
                  <li>Supply Chains</li>
                  <li>KYC</li>
                  <li>Identity Documents</li>
                  <li>Payments</li>
                </ul>
              </div>
              <div className="col-md-2">
                <ul
                  className="list-unstyled quick-links"
                  style={{ color: "gray" }}
                >
                  <li>HyperLedger </li>
                  <li>Custom-Blockchain</li>
                  <li>QUORUM </li>
                  <li>Wallet </li>
                  <li>Dapps </li>
                  <li>Coin/STO/ICO </li>
                  <li>Exchange </li>
                </ul>
              </div>
              <div className="col-md-2">
                <ul
                  className="list-unstyled quick-links"
                  style={{ color: "gray" }}
                >
                  <li>.NET MVC </li>
                  <li>Java</li>
                  <li>SQL</li>
                  <li>Solidity</li>
                  <li>Swift </li>
                  <li>Android SDK</li>
                  <li>Angular JS</li>
                  <li>SignalR</li>
                  <li>Javascript</li>
                  <li>C sharp</li>
                  <li>PHP</li>
                </ul>
              </div>
              <div className="col-md-2">
                <ul
                  className="list-unstyled quick-links"
                  style={{ color: "gray" }}
                >
                  <li>
                    <ScrollLink
                      to="about"
                      spy={true}
                      hashSpy={true}
                      offset={0}
                      smooth={true}
                      duration={500}
                      style={{ cursor: "pointer" }}
                    >
                      About
                    </ScrollLink>{" "}
                  </li>
                  <li>
                    <Link to="/portfolio">Brand</Link>
                  </li>
                  <li>
                    <Link to="/">Partners</Link>
                  </li>
                  <li>
                    <Link to="/usecase">Case Studies</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blog</Link>
                  </li>
                  <li>
                    <HashLink to="/#contact" style={{ cursor: "pointer" }}>
                      Contact
                    </HashLink>
                  </li>
                </ul>
              </div>
              <div className="col-md-2">
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "12px",
                    marginLeft: "-10px",
                    color: "gray"
                  }}
                >
                  <img
                    src="images/flags/french.png"
                    alt="image"
                    style={{ width: "17%" }}
                  />
                  <div className="mt-2" style={{ lineHeight: "1.3" }}>
                    <a
                      href="https://www.google.com/maps/@48.8906711,2.2432959,3a,46.7y,308.59h,100.88t/data=!3m7!1e1!3m5!1s7KZHraJy400RyE8gdCjCMQ!2e0!6s%2F%2Fgeo3.ggpht.com%2Fcbk%3Fpanoid%3D7KZHraJy400RyE8gdCjCMQ%26output%3Dthumbnail%26cb_client%3Dsearch.revgeo_and_fetch.gps%26thumb%3D2%26w%3D96%26h%3D64%26yaw%3D15.535827%26pitch%3D0%26thumbfov%3D100!7i13312!8i6656"
                      target="_blank"
                    >
                      12 Place de la Défense
                      <br />
                      Paris-la Défense.92974
                      <br />
                      France
                      <br />
                      <br />
                    </a>
                  </div>
                  <img
                    src="images/flags/uk.jpg"
                    className="mt-2"
                    alt="image"
                    style={{ width: "15%" }}
                  />
                  <div className="mt-2" style={{ lineHeight: "1.3" }}>
                    <a
                      href="https://goo.gl/maps/oAD3ADwLxWU84Dxb7"
                      target="_blank"
                    >
                      Kemp House 160 City Road <br />
                      London EC1V 2NX
                      <br />
                      United Kingdom
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4"></div>
              <div className="col-md-2 col-md-offset-2">
                <div className="button-input">
                  <Subscriber />
                </div>

                <ul className="list-unstyled list-inline social text-center foot1">
                  <li className="list-inline-item">
                    <a
                      href="https://www.linkedin.com/company/venturexlabs/"
                      target="_blank"
                    >
                      <Icofont
                        icon="linkedin"
                        style={{
                          backgroundColor: "#041319",
                          color: "#fff",
                          borderRadius: "25px",
                          padding: "8px 9px 8px 9px",
                          border: "4px solid #536a5f"
                        }}
                      />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/venturexlabs/?modal=admin_todo_tour"
                      target="_blank"
                    >
                      <Icofont
                        icon="facebook"
                        style={{
                          backgroundColor: "#041319",
                          color: "#fff",
                          borderRadius: "25px",
                          padding: "8px 9px 8px 9px",
                          border: "4px solid #536a5f"
                        }}
                      />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      target="_blank"
                      href="https://twitter.com/venturexlabs?lang=en"
                    >
                      <Icofont
                        icon="twitter"
                        style={{
                          backgroundColor: "#041319",
                          color: "#fff",
                          borderRadius: "25px",
                          padding: "8px 9px 8px 9px",
                          border: "4px solid #536a5f"
                        }}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className="container-fluid mt-2"
            style={{ backgroundColor: "#051820" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-4 offset-md-4">
                  <a href="#">
                    <p style={{ color: "#cf1016", textAlign: "center" }}>
                      © 2020 VenturexLabs | Privacy Policy{" "}
                    </p>
                  </a>
                </div>
                <div className="col-md-4">
                  {/* <ul className='flex-end' style={{marginBottom: 0}}>
                            <li className="px-1">
                            <a className='social-icon' href="https://www.linkedin.com/company/venturexlabs/" target="_blank">
                                <Icofont icon="linkedin"/>
                                    </a>
                            </li>
                            <li className='px-1'>
                            <a className='social-icon' href="https://www.facebook.com/venturexlabs/?modal=admin_todo_tour" target="_blank">
                                        <Icofont icon="facebook" />
                                        </a>
                            </li>
                            <li className='px-1'>
                            <a target="_blank" href="https://twitter.com/venturexlabs?lang=en" className='social-icon'>
                                        <Icofont icon="twitter"/>
                                        </a>
                            </li>
                        </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="FooterForMobile hidden-md"
          style={{
            backgroundImage: `url(${BG})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: "#041319",
            paddingTop: "3%",
            color: "white",
            fontWeight: "100",
            fontSize: "11.5px",
            lineHeight: "1.6"
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-6 py-3">
                <Link to="/">
                  <img
                    src="images/vxlabs-logo.png"
                    className="Footer-img"
                    alt="logo"
                  />
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-xs-6">
                <h6
                  className="pb-2"
                  style={{
                    display: "inline-block",
                    borderBottom: "1px solid #d3d3d3"
                  }}
                >
                  INDUSTRIES
                </h6>
                {/* <hr style={{ width: "100px", float: "left" }} /> */}
                <br />
                <ul
                  className="list-unstyled quick-links"
                  style={{ color: "gray" }}
                >
                  <li>FINTECH</li>
                  <li>Crypto Assets</li>
                  <li>Insurance</li>
                  <li>Public Services</li>
                  <li>Data Analytics</li>
                  <li>Supply Chains</li>
                  <li>KYC</li>
                  <li>Identity Documents</li>
                  <li>Payments</li>
                </ul>
              </div>
              <div className="col-sm-6 col-xs-6">
                <h6
                  className="pb-2"
                  style={{
                    display: "inline-block",
                    borderBottom: "1px solid #d3d3d3"
                  }}
                >
                  SOLUTIONS
                </h6>
                {/* <hr style={{ width: "100px", float: "left" }} /> */}
                <br />
                <ul
                  className="list-unstyled quick-links"
                  style={{ color: "gray" }}
                >
                  <li>HyperLedger </li>
                  <li>Custom-Blockchain</li>
                  <li>QUORUM </li>
                  <li>Wallet </li>
                  <li>Dapps </li>
                  <li>Coin/STO/ICO </li>
                  <li>Exchange </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-xs-6">
                <h6
                  className="pb-2"
                  style={{
                    display: "inline-block",
                    borderBottom: "1px solid #d3d3d3"
                  }}
                >
                  TECHNOLOGIES
                </h6>
                {/* <hr style={{ width: "100px", float: "left" }} /> */}
                <br />
                <ul
                  className="list-unstyled quick-links"
                  style={{ color: "gray" }}
                >
                  <li>.NET MVC </li>
                  <li>Java</li>
                  <li>SQL</li>
                  <li>Solidity</li>
                  <li>Swift </li>
                  <li>Android SDK</li>
                  <li>Angular JS</li>
                  <li>SignalR</li>
                  <li>Javascript</li>
                  <li>C sharp</li>
                  <li>PHP</li>
                </ul>
              </div>
              <div className="col-sm-6 col-xs-6">
                <h6
                  className="pb-2"
                  style={{
                    display: "inline-block",
                    borderBottom: "1px solid #d3d3d3"
                  }}
                >
                  COMPANY
                </h6>
                {/* <hr style={{ width: "100px", float: "left" }} /> */}
                <br />
                <ul
                  className="list-unstyled quick-links"
                  style={{ color: "gray" }}
                >
                  <li>
                    <ScrollLink
                      to="about"
                      spy={true}
                      offset={0}
                      smooth={true}
                      duration={500}
                      style={{ cursor: "pointer" }}
                    >
                      About
                    </ScrollLink>{" "}
                  </li>
                  <li>
                    <Link to="/portfolio">Brand</Link>
                  </li>
                  <li>
                    <Link to="/">Partners</Link>
                  </li>
                  <li>
                    <Link to="/usecase">Case Studies</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blog</Link>
                  </li>
                  <li>
                    <ScrollLink
                      to="contactUs"
                      spy={true}
                      offset={20}
                      duration={500}
                      style={{ cursor: "pointer" }}
                    >
                      Contact
                    </ScrollLink>
                  </li>
                </ul>
                <p
                  style={{ textAlign: "left", fontSize: "12px", color: "gray" }}
                >
                  <img src="images/flags/french.png" style={{ width: "10%" }} />
                  <br />
                  12 Place de la Défense
                  <br />
                  Paris-la Défense.92974
                  <br />
                  France
                  <br /> <br />
                  <img src="images/flags/uk.jpg" style={{ width: "10%" }} />
                  <br />
                  Kemp House 160 City Road <br />
                  London EC1V 2NX
                  <br />
                  United Kingdom
                </p>
                <br />
                <div className="button-input">
                  <Subscriber />
                </div>

                <ul className="list-unstyled list-inline social text-center foot1">
                  <li className="list-inline-item">
                    <a
                      href="https://www.linkedin.com/company/venturexlabs/"
                      target="_blank"
                    >
                      <Icofont
                        icon="linkedin"
                        style={{
                          backgroundColor: "#041319",
                          color: "#fff",
                          borderRadius: "25px",
                          padding: "8px 9px 8px 9px",
                          border: "4 px solid #536a5f"
                        }}
                      />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/venturexlabs/?modal=admin_todo_tour"
                      target="_blank"
                    >
                      <Icofont
                        icon="facebook"
                        style={{
                          backgroundColor: "#041319",
                          color: "#fff",
                          borderRadius: "25px",
                          padding: "8px 9px 8px 9px",
                          border: "4 px solid #536a5f"
                        }}
                      />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      target="_blank"
                      href="https://twitter.com/venturexlabs?lang=en"
                    >
                      <Icofont
                        icon="twitter"
                        style={{
                          backgroundColor: "#041319",
                          color: "#fff",
                          borderRadius: "25px",
                          padding: "8px 9px 8px 9px",
                          border: "4 px solid #536a5f"
                        }}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className="container-fluid mt-2"
            style={{ backgroundColor: "#051820" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-sm-6">
                  <a href="#">
                    <p style={{ color: "#cf1016", textAlign: "center" }}>
                      © 2020 VenturexLabs | Privacy Policy{" "}
                    </p>
                  </a>
                </div>
                <div className="col-sm-6">
                  {/* <ul className='flex-end' style={{marginBottom: 0}}>
                            <li className="px-2">
                            <a className='social-icon' href="https://www.linkedin.com/company/venturexlabs/" target="_blank">
                                <Icofont icon="linkedin"/>
                                    </a>
                            </li>
                            <li className='px-2'>
                            <a className='social-icon' href="https://www.facebook.com/venturexlabs/?modal=admin_todo_tour" target="_blank">
                                        <Icofont icon="facebook" />
                                        </a>
                            </li>
                            <li className='px-2'>
                            <a target="_blank" href="https://twitter.com/venturexlabs?lang=en" className='social-icon'>
                                        <Icofont icon="twitter"/>
                                        </a>
                            </li>
                        </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
