import React from "react";

export default ({ width, height, fill, bg }) => {
  return (
    <svg
      style={{ backgroundColor: bg }}
      fill={fill}
      width={width}
      height={height}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 454.44 454.35"
    >
      <g>
        <path
          class="st0"
          d="M231.38,199.16c-1-2.12-2.69-3.76-4.09-5.58c-5.27-6.83-10.59-13.61-15.93-20.38
		c-4.97-6.32-10.02-12.57-14.97-18.9c-4.2-5.36-8.29-10.81-12.48-16.18c-4.32-5.53-8.73-10.99-13.06-16.51
		c-1.72-2.2-3.37-4.46-4.98-6.75c-0.61-0.87-1.1-1.87-1.45-2.87c-0.67-1.89-0.03-3.19,1.85-3.93c1.98-0.77,4.1-0.9,6.18-0.93
		c7.15-0.1,14.3-0.07,21.45-0.11c19.01-0.09,38.02-0.2,57.03-0.28c3.56-0.01,7.13,0.08,10.52,1.33c3.81,1.41,6.05,4.64,8.5,7.58
		c21.29,25.46,42.56,50.94,63.84,76.41c8.73,10.45,17.44,20.92,26.16,31.37c0.43,0.52,0.92,1,1.38,1.49c0.6,1.2,1.61,2.06,2.48,3.04
		c3.65,4.4,7.36,8.76,10.95,13.21c6.43,7.97,13.55,15.39,19.29,23.92c0.35,1.24,1.03,2.35,1.16,3.69c0.19,1.98-0.29,3-2.17,3.8
		c-4.29,1.84-8.58,3.67-12.86,5.51c-0.48,0.17-0.96,0.33-1.43,0.52c-12.19,5.05-24.38,10.11-36.58,15.14
		c-6.75,2.79-13.49,5.62-20.3,8.24c-3.3,1.26-6.61,0.84-9.49-1.39c-2.11-1.63-4.05-3.47-5.7-5.58
		c-8.46-10.8-16.89-21.62-25.35-32.41c-4.26-5.44-8.57-10.84-12.85-16.26l0-0.01c-4.74-6.35-9.66-12.57-14.64-18.73
		c-1.11-1.86-2.69-3.34-3.98-5.05c-3.46-4.48-6.92-8.95-10.39-13.42c-0.12-0.16-0.38-0.22-0.57-0.32
		C236.62,205.42,234.03,202.27,231.38,199.16z"
        />
        <path
          class="st0"
          d="M134,305.39c-2.2-0.53-4.41-1.01-6.59-1.6c-1.29-0.35-2.54-0.86-2.32-2.61c0.48-1.04,1.34-1.68,2.36-2.08
		c2.63-1.04,5.26-2.1,7.92-3.07c18.51-6.76,37.01-13.57,55.56-20.21c11.08-3.97,21.97-8.46,33.3-11.71c1.02-0.1,2.07,0.14,3.04-0.35
		c1.09,0.55,2.25,0.22,3.38,0.3c0.18,0.17,0.38,0.25,0.61,0.11c0.37,0.05,0.73,0.1,1.1,0.14c4.54,0.66,9.09,1.32,13.63,1.99
		c6.24,0.92,12.48,1.84,18.72,2.77c0.95,0.04,1.3,0.83,1.77,1.44c8.72,11.33,17.42,22.67,26.14,34.01
		c3.66,4.77,7.18,9.66,11.75,13.64c4.88,4.24,10.22,5.38,16.2,2.53c1.18-0.56,2.39-1.05,3.58-1.57
		c21.01-9.23,42.02-18.46,63.03-27.7c0.92-0.4,1.79-0.51,2.79-0.31c5.22,1.02,10.47,1.88,15.67,2.98c2.67,0.56,5.59,0.43,7.86,2.37
		c0,0.53,0,1.06,0,1.58c-0.99,1.07-2.12,1.98-3.31,2.81c-3.83,1.98-7.36,4.46-11.04,6.69c-1.48,0.89-3.06,1.64-4.36,2.82
		c-7.15,4.38-14.31,8.75-21.45,13.15c-11.34,6.99-22.66,14.02-34.01,20.99c-2.98,1.83-6.25,2.74-9.77,3.01
		c-6.81,0.52-13.26-1.36-19.76-2.8c-29.92-6.65-59.83-13.36-89.74-20.06c-21.97-4.92-43.93-9.88-65.89-14.81
		c-0.49-0.11-1.01-0.11-1.51-0.17C146.45,308.25,140.23,306.82,134,305.39z"
        />
        <path
          class="st0"
          d="M118.26,301.93c-0.02,3.33-0.1,6.67,0.31,9.98c0.18,1.47-0.28,2.18-1.78,2.65c-6.84,2.12-13.63,4.39-20.44,6.6
		c-6.13,1.81-12.23,3.73-18.26,5.86c-6.3,1.76-12.49,3.88-18.72,5.88c-5.07,1.47-10.06,3.22-15.05,4.96
		c-0.93,0.1-1.86,0.21-2.79,0.31c-1.44-1.74,0.06-3.05,0.93-4.08c1.92-2.28,4.06-4.41,6.32-6.35c2.95-2.54,5.61-5.36,8.31-8.13
		c0.87-0.85,1.75-1.7,2.62-2.55c5.45-5.17,10.77-10.48,16-15.87c0.93-0.72,1.8-1.5,2.45-2.5c5.67-5.19,10.99-10.73,16.39-16.2
		c0.76-0.73,1.52-1.47,2.27-2.2c5.7-5.39,11.27-10.92,16.72-16.56c0.77-0.57,1.45-1.24,2.02-2.01c2.3-2.24,4.61-4.47,6.89-6.73
		c3.36-3.33,6.7-6.68,10.05-10.02c0.73-0.55,1.51-1.06,1.8-2c5.51-5.51,11.41-10.62,16.72-16.35c0.55-0.55,1.11-1.1,1.66-1.65
		c1.74-1.68,3.49-3.36,5.22-5.06c4.07-3.99,8.23-7.88,12.11-12.05c0.7-0.57,1.53-1.03,1.76-2.02c1.86-1.71,3.78-3.35,5.6-5.1
		c4.04-3.89,7.64-8.24,12.22-11.56c2.17-1.61,4.57-2.71,7.16-3.48c1.21-0.36,1.99-0.36,2.87,0.79c8.79,11.41,17.66,22.75,26.5,34.12
		c0.35,0.44,0.73,0.85,1.1,1.28c1.35,2.38,1.27,2.67-1.05,4.44c-0.54,0.41-1.05,0.87-1.55,1.33
		c-15.96,14.74-31.91,29.48-47.86,44.23c-0.55,0.51-1.13,0.9-1.85,1.16c-13.42,4.88-26.83,9.77-40.23,14.7
		c-4.78,1.76-9.66,3.27-14.3,5.41c-1.25,0.58-2.3,1.46-2.39,2.87C117.88,297.98,117.76,299.98,118.26,301.93z"
        />
      </g>
    </svg>
  );
};
