import React from "react";

export default ({ width, height, fill, bg }) => {
  return (
    <svg
      style={{ backgroundColor: bg }}
      fill={fill}
      width={width}
      height={height}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 454.44 454.35"
    >
      <g id="Online_Payment_1_">
        <path
          class="st0"
          d="M201.59,350.23h51.27c2.83,0,5.13-2.3,5.13-5.13v-5.13h-61.52v5.13C196.46,347.93,198.75,350.23,201.59,350.23
		z"
        />
        <path
          class="st0"
          d="M268.24,345.1c0,8.49-6.89,15.38-15.38,15.38l0,0h-51.27c-8.49,0-15.38-6.89-15.38-15.38l0,0v-5.13h-66.65
		v15.38c0,8.49,6.89,15.38,15.38,15.38h184.57c8.49,0,15.38-6.89,15.38-15.38v-15.38h-66.65V345.1z"
        />
        <path
          class="st0"
          d="M140.06,222.05c0-2.83,2.3-5.13,5.13-5.13h123.05v-10.25h-133.3c-2.83,0-5.13,2.3-5.13,5.13v117.92h10.25
		V222.05z"
        />
        <path class="st0" d="M314.38,278.45h10.25v51.27h-10.25V278.45z" />
        <path
          class="st0"
          d="M129.81,83.62c-25.48,0-46.14,20.66-46.14,46.14s20.66,46.14,46.14,46.14s46.14-20.66,46.14-46.14
		C175.92,104.29,155.28,83.65,129.81,83.62z M129.81,124.64c11.3-0.01,20.48,9.14,20.49,20.44c0.01,9.35-6.31,17.52-15.37,19.85
		v5.86h-10.25v-5.86c-9.03-2.33-15.35-10.46-15.38-19.78h10.25c0,5.66,4.59,10.25,10.25,10.25c5.66,0,10.25-4.59,10.25-10.25
		c0-5.66-4.59-10.25-10.25-10.25c-11.3,0.01-20.48-9.14-20.49-20.44c-0.01-9.35,6.31-17.52,15.37-19.85v-5.86h10.25v5.86
		c9.03,2.33,15.35,10.46,15.38,19.78h-10.25c0-5.66-4.59-10.25-10.25-10.25c-5.66,0-10.25,4.59-10.25,10.25
		S124.14,124.64,129.81,124.64z"
        />
        <path class="st0" d="M299,237.43h10.25v10.25H299V237.43z" />
        <circle class="st0" cx="227.22" cy="129.76" r="5.13" />
        <circle class="st0" cx="324.63" cy="88.75" r="5.13" />
        <path
          class="st0"
          d="M268.24,227.18H150.31v102.54h153.81v-51.27h-20.51c-8.49,0-15.38-6.89-15.38-15.38V227.18z M261.72,296.31
		l-21.75,21.75c-0.96,0.96-2.27,1.5-3.62,1.5h-0.32c-1.47-0.09-2.82-0.81-3.73-1.97l-21.81-28.04l-6.77,6.77
		c-2.01,1.99-5.26,1.98-7.25-0.03c-0.84-0.84-1.35-1.95-1.47-3.13l-3.66-39.89c-0.27-2.82,1.8-5.32,4.61-5.59
		c0.32-0.03,0.65-0.03,0.97,0l39.88,3.62c2.82,0.27,4.89,2.77,4.62,5.59c-0.11,1.18-0.63,2.29-1.47,3.13l-6.77,6.77l28.04,21.82
		c2.24,1.73,2.65,4.95,0.92,7.19c-0.14,0.18-0.28,0.34-0.44,0.5V296.31z"
        />
        <path
          class="st0"
          d="M220.35,267.63c-0.09-1.47,0.45-2.91,1.49-3.95l3.13-3.13l-22.87-2.1l2.08,22.86l3.14-3.14
		c0.96-0.96,2.27-1.5,3.62-1.5h0.32c1.47,0.09,2.82,0.81,3.73,1.97l21.81,28.04l13.54-13.54l-28.04-21.82
		C221.15,270.43,220.44,269.09,220.35,267.63z"
        />
        <path class="st0" d="M340.02,237.43h10.25v10.25h-10.25V237.43z" />
        <path
          class="st0"
          d="M365.65,134.89h-82.03c-2.83,0-5.13,2.3-5.13,5.13v123.05c0,2.83,2.3,5.13,5.13,5.13h82.03
		c2.83,0,5.13-2.3,5.13-5.13V140.02C370.78,137.18,368.48,134.89,365.65,134.89z M319.51,252.81c0,2.83-2.3,5.13-5.13,5.13h-20.51
		c-2.83,0-5.13-2.3-5.13-5.13V232.3c0-2.83,2.3-5.13,5.13-5.13h20.51c2.83,0,5.13,2.3,5.13,5.13V252.81z M319.51,211.8
		c0,2.83-2.3,5.13-5.13,5.13h-20.51c-2.83,0-5.13-2.3-5.13-5.13v-20.51c0-2.83,2.3-5.13,5.13-5.13h20.51c2.83,0,5.13,2.3,5.13,5.13
		V211.8z M360.52,252.81c0,2.83-2.3,5.13-5.13,5.13h-20.51c-2.83,0-5.13-2.3-5.13-5.13V232.3c0-2.83,2.3-5.13,5.13-5.13h20.51
		c2.83,0,5.13,2.3,5.13,5.13V252.81z M360.52,211.8c0,2.83-2.3,5.13-5.13,5.13h-20.51c-2.83,0-5.13-2.3-5.13-5.13v-20.51
		c0-2.83,2.3-5.13,5.13-5.13h20.51c2.83,0,5.13,2.3,5.13,5.13V211.8z M360.52,170.78c0,2.83-2.3,5.13-5.13,5.13h-61.52
		c-2.83,0-5.13-2.3-5.13-5.13v-20.51c0-2.83,2.3-5.13,5.13-5.13h61.52c2.83,0,5.13,2.3,5.13,5.13V170.78z"
        />
        <path class="st0" d="M299,155.4h51.27v10.25H299V155.4z" />
        <path class="st0" d="M340.02,196.41h10.25v10.25h-10.25V196.41z" />
        <path class="st0" d="M299,196.41h10.25v10.25H299V196.41z" />
        <path
          class="st0"
          d="M329.76,103.2v21.43h-10.25V103.2C322.81,104.43,326.45,104.43,329.76,103.2z"
        />
        <path
          class="st0"
          d="M309.25,88.75c-0.01,1.75,0.3,3.49,0.92,5.13h-77.83v21.43c-3.31-1.23-6.95-1.23-10.25,0V88.75
		c0-2.83,2.3-5.13,5.13-5.13h82.96C309.56,85.26,309.24,86.99,309.25,88.75z"
        />
        <path
          class="st0"
          d="M211.84,129.76c-0.01,1.75,0.3,3.49,0.92,5.13h-26.81c0.15-1.69,0.26-3.38,0.26-5.13s-0.1-3.44-0.26-5.13
		h26.81C212.14,126.27,211.83,128.01,211.84,129.76z"
        />
      </g>
    </svg>
  );
};
