import React, { Component } from "react";
import * as firebase from "firebase";

class FeaturedInsight extends Component {
  state = {
    insight: []
  };
  componentDidMount = () => {
    const insightRef = firebase.database().ref("/insight");
    insightRef.on("value", snap => {
      if (snap.val()) {
        this.setState({
          insight: this.convertToArray(snap.val())
        });
      }
    });
  };
  convertToArray(Objects) {
    const arrayResult = Object.keys(Objects).map(key => {
      return { id: key, data: Objects[key] };
    });
    return arrayResult;
  }
  render() {
    const { languageKeywords, selectedLanguage } = this.props.language;
    const FeaturedInsight = this.state.insight.map(val => (
      <div className="col-md-3 col-sm-4 col-xs-12" key={val.id}>
        <div className="card">
          <div className="item-img">
            <img src={val.data.image} loading="lazy" alt="image" />
            <div className="Card_Details">
              <div className="Card_Content">
                <a href="#">
                  <b style={{ fontSize: "25px", padding: "10px 0px" }}>
                    {val.data.title}
                  </b>
                </a>

                <p
                  style={{ textAlign: "left", padding: "15px", color: "#000" }}
                >
                  {val.data.paragraph}{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
    return (
      <div className="FeatureInsight mb-5">
        <div className="container tab-content">
          <div>
            {/* featured_insights */}
            <h2 align="center">
              {languageKeywords.featured_insights[selectedLanguage.language]}
            </h2>
          </div>
          <div className="tab-pane fade in active show">
            <div classNameName="container">
              <div className="row" style={{ marginTop: "20px" }}>
                {FeaturedInsight}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FeaturedInsight;
