import React, { Component } from 'react';
import FintechImg from '../images/industry_page/1-fintech.jpg';
import FintechIcon from '../images/icons/1.png';
import CryptoImg from '../images/industry_page/2-crypto-asset.jpg';
import CryptoIcon from '../images/icons/2.png';
import InsuranceImg from "../images/industry_page/3-insurance.jpg";
import InsuranceIcon from '../images/icons/3.png';
import PublicServiceImg from '../images/industry_page/4-public-service.jpg';
import PublicServiceIcon from '../images/icons/4.png';
import DataImg from '../images/industry_page/5-data-analytics.jpg';
import DataIcon from '../images/icons/5.png';
import SupplyImg from '../images/industry_page/6-supply-chains.jpg';
import SupplyIcon from '../images/icons/6.png';


class IndustriesPage extends Component {
    render() {
        const { languageKeywords, selectedLanguage } = this.props.language;
        return (
            <section id="portfolio" class="portfolio section-padding" data-scroll-index="2">
                <div class="container">
                    <div class="row">

                        <div class="section-head col-sm-12">
                            <h4>
                                <span></span><br />
                                {languageKeywords.industries[selectedLanguage.language]}
                            </h4>
                        </div>
                        <div class="tab-content">
                            <div class="tab-pane fade in active show">
                                <div class="row" style={{ marginTop: "20px" }}>

                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                        <div class="card">
                                            <div class="item-img">
                                                <img src={FintechImg} loading="lazy" alt="image" />
                                                <div class="industry__icon">
                                                    <img src={FintechIcon} alt="image" />
                                                </div>
                                                <div class="Card_Details">
                                                    <ul>
                                                        <li class="Card_Align">
                                                            <a href="#"><b style={{ fontSize: "25px" }}>-Fintech-</b></a>
                                                            <br /><hr class="new1" />
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Asset management</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Investments</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Manufacturing</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Fundraising</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Payments</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                        <div class="card">
                                            <div class="item-img">
                                                <img src={CryptoImg} loading="lazy" alt="image" />
                                                <div class="industry__icon">
                                                    <img src={CryptoIcon} />
                                                </div>
                                                <div class="Card_Details">
                                                    <ul>
                                                        <li class="Card_Align">
                                                            <a href="#"><b style={{ fontSize: "25px" }}>-Crypto Assets-</b></a>
                                                            <br /><hr class="new1" />
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Digital Currencies</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Crypto Exchanges</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Mining Operations</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">ICOs / STOs</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#"></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                        <div class="card">
                                            <div class="item-img">
                                                <img src={InsuranceImg} loading="lazy" alt="image" />
                                                <div class="industry__icon">
                                                    <img src={InsuranceIcon} />
                                                </div>
                                                <div class="Card_Details">
                                                    <ul>
                                                        <li class="Card_Align">
                                                            <a href="#"><b style={{ fontSize: "25px" }}>-Insurance-</b></a>
                                                            <br /><hr class="new1" />
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">KYC</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Payment Systems</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Smart Contracts</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Fraud Prevention</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Risk Management</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12" style={{ height: "20px" }}></div>
                                    {/* <!-- Card 4-6================ --> */}
                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                        <div class="card">
                                            <div class="item-img">
                                                <img src={PublicServiceImg} alt="image" />
                                                <div class="industry__icon">
                                                    <img src={PublicServiceIcon} />
                                                </div>
                                                <div class="Card_Details">
                                                    <ul>
                                                        <li class="Card_Align">
                                                            <a href="#"><b style={{ fontSize: "25px" }}>-Public Services-</b></a>
                                                            <br /><hr class="new1" />
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Identity Documents</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Personal Data</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Voting Procedures</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Taxation</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#"></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                        <div class="card">
                                            <div class="item-img">
                                                <img src={DataImg} loading="lazy" alt="image" />
                                                <div class="industry__icon">
                                                    <img src={DataIcon} />
                                                </div>
                                                <div class="Card_Details">
                                                    <ul>
                                                        <li class="Card_Align">
                                                            <a href="#"><b style={{ fontSize: "25px" }}>-Data / Analytics-</b></a>
                                                            <br /><hr class="new1" />
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Fraud Prevention</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Cost Efficient Consumption</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Decentralised Networks</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Data Quality Management</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Real-Time Tracking Of Data</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-md-4 col-sm-4 col-xs-12">
                                        <div class="card">
                                            <div class="item-img">
                                                <img src={SupplyImg} loading="lazy" alt="image" />
                                                <div class="industry__icon">
                                                    <img src={SupplyIcon} />
                                                </div>
                                                <div class="Card_Details">
                                                    <ul>
                                                        <li class="Card_Align">
                                                            <a href="#"><b style={{ fontSize: "25px" }}>-Supply Chains-</b></a>
                                                            <br /><hr class="new1" />
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Fraud Prevention</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Inventory Management</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Payment Systems</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Tracking</a>
                                                        </li>
                                                        <li class="Card_Align">
                                                            <a href="#">Smart Contracts</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="col-md-12" style={{ height: "20px" }}></div>
                        {/*    <!-- End of Main Card  =================
                 -->
                <!-- =============start of center Portion  =================
                -->
                <!-- <div class="col-md-12" style="height: 20px"></div>
                        <div class="tab-content" style="background-color:#d5d8dc;width: 100% !important;height: 300px;">
                    <div class="row" style="margin-top: 20px">
                    <div class="col-md-4 col-sm-4 col-xs-12">
                       <div class="item-img">
                                <div id="Center1">
                                  <sub style="font-size: 280% !important; bottom: -1.46em !important;">$</sub>
                                  <span class="count">7684</span>
                                  <sup style="top: -1.1em !important;left: 160% !important;font-size: 280% !important;}">M</sup>
                                </div>
                                <p style="text-align: center !important; color: #000000 !important;margin-top: -20% !important; margin-left: 15%;">
                                  Projected Blockchain<br/>
                                    Market in 2022
                                </p>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12">
                       <div class="item-img">
                                <div id="Center1">
                                   <sub style="font-size: 280% !important; bottom: -1.46em !important;left: -62% !important;">$</sub>
                                  <span class="count" style="margin-left: -34% !important;font-size: 36px !important;">107797437804</span></div>
                                <p style="text-align: center !important; color: #000000 !important;margin-top: -20% !important; margin-left:8% !important; ">
                                 current Cryptocurrency<br>
                                        Market Cap
                                </p>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-4 col-xs-12">
                       <div class="item-img">
                                <div id="Center1">
                                   <sub style="font-size: 280% !important; bottom: -1.46em !important;">%</sub>
                                  <span class="count">25</span></div>
                                <p style="text-align: center !important; color: #000000 !important;margin-top: -20% !important;">
                                 Projected proportion of Global 2000<br/>companies using blockchain<br/>
                                  services in 2021
                                </p>
                      </div>
                    </div>
                  </div>
                </div> -->
                <!-- End of Center Portion  =================
                -->
                <!--
                   =================Features Insight Start==============
            -->  */}
                        <div class="clear-fix"></div>


                    </div>


                </div>
            </section>
        )
    }
}

export default IndustriesPage;
