import React, { Component } from "react";
import Icofont from "react-icofont";
import * as firebase from "firebase";
import getCurrentDate from "./CurrentDate";
import "./Subscriber.css";
import swal from "sweetalert";
import axios from "axios";

class Subscriber extends Component {
  state = {
    email: "",
    to: ""
  };
  componentDidMount = e => {
    const mailRef = firebase.database().ref("/mailto");
    mailRef.on("value", snap => {
      if (snap.val()) {
        this.setState({ to: snap.val().mail });
      }
    });
  };
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleSubmit = async e => {
    e.preventDefault();
    try {
      var date = new Date();
      this.setState({ date });
      let SubscriberRef = firebase.database().ref("/subscriber");
      SubscriberRef.push(this.state);
      let messageRef = firebase.database().ref("/message");
      messageRef.push(this.state);
      axios
        .post("https://email-server-fse.herokuapp.com/send", {
          to: this.state.to,
          from: `${this.state.email} Sent you subscription message at Venturexlabs`,
          subject: `${this.state.email} wants to subscribe to your newsletter`,
          text: `Email: ${this.state.email}`
        })
        .then(res => {
          swal({
            title: "Thank You!",
            text: "your message has been sent successfully.",
            type: "success",
            timer: 3000
          });
          this.setState({
            email: ""
          });
        });
    } catch (err) {
      console.log(err);
    }
  };
  ValidateEmail = mail => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    alert("You have entered an invalid email address!");
    return false;
  };
  render() {
    return (
      <form style={{ position: "relative" }} onSubmit={this.handleSubmit}>
        <input
          autoComplete="off"
          className="form-control AlienWare"
          id="Email"
          name="email"
          placeholder="Enter Email..."
          required="required"
          type="email"
          value={this.state.email}
          onChange={this.handleChange}
        />
        <input
          id="redirectUrl"
          name="redirectUrl"
          type="hidden"
          value="/Home/Index"
        />{" "}
        <button type="submit" name="Subscribe">
          <Icofont
            icon="location-arrow"
            id="Sender-emoji"
            style={{ fontSize: "20px" }}
          />
        </button>
      </form>
    );
  }
}

export default Subscriber;
