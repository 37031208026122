import React, { Component } from "react";
import * as firebase from "firebase";
import RecentPosts from "./RecentPosts";
import Comments from "./Comments";
import PostComment from "./PostComment";
// import doTranslate from "../helpers/googleTranslateHelper";
import GetDate from "../helpers/GetDate";
class BlogDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BlogDetail: [],
      languageChangeCount: props.language.languageChangeCount
    };
  }
  componentDidUpdate() {
    if (
      this.state.languageChangeCount != this.props.language.languageChangeCount
    ) {
      console.log("Language Change Detected");
      this.setState(
        {
          languageChangeCount: this.props.language.languageChangeCount
        },
        cb => {
          this.translation();
        }
      );
    }
  }
  componentDidMount() {
    let postRef = firebase
      .database()
      .ref("/blogs")
      .child(this.props.match.params.id);
    postRef.on("value", snap => {
      if (snap.val()) {
        this.setState({ BlogDetail: snap.val() }, cb => {
          this.translation();
        });
      }
    });
  }
  translation() {
    let that = this;
    let BlogDetail = this.state.BlogDetail;

    // doTranslate(
    //   BlogDetail.title,
    //   that.props.language.selectedLanguage.language
    // ).then(trans => {
    //   BlogDetail.title = trans;
    //   that.setState({ BlogDetail });
    // });

    // doTranslate(
    //   BlogDetail.detail,
    //   that.props.language.selectedLanguage.language
    // ).then(trans => {
    //   BlogDetail.detail = trans;
    //   that.setState({ BlogDetail });
    // });
  }
  onPostComment = (comment, callBack) => {
    const date = new Date();
    comment.date = GetDate(date);

    let commentRef = firebase
      .database()
      .ref("/blogs")
      .child(this.props.match.params.id)
      .child("comments");
    commentRef.push(comment);

    callBack();
  };
  convertToArray(Objects) {
    const arrayResult = Object.keys(Objects).map(key => {
      return { id: key, data: Objects[key] };
    });
    return arrayResult;
  }
  render() {
    let comments = [];
    if (this.state.BlogDetail && this.state.BlogDetail.comments)
      comments = this.convertToArray(this.state.BlogDetail.comments);
    const { languageKeywords, selectedLanguage } = this.props.language;
    return (
      <section className="blogs section-padding">
        <div className="container">
          <div className="row">
            <div className="order-2 order-md-1 col-md-8">
              <div className="posts">
                <div className="post mb-80">
                  <div className="post-images">
                    <a href="#0" className="full-width">
                      <img src={this.state.BlogDetail.image} alt="Image" />
                    </a>
                  </div>
                  <div className="content">
                    <div className="post-meta">
                      <div className="post-title">
                        <h5>
                          <a href="#0">{this.state.BlogDetail.title}</a>
                        </h5>
                      </div>

                      <ul className="meta">
                        <li>
                          <a href="#0">
                            <i
                              className="fa fa-user mr-1"
                              aria-hidden="true"
                            ></i>
                            {this.state.BlogDetail.user}
                          </a>
                        </li>
                        <li>
                          <a href="#0">
                            <i
                              className="fa fa-calendar mr-1"
                              aria-hidden="true"
                            ></i>
                            {this.state.BlogDetail.date}
                          </a>
                        </li>
                        <li>
                          <a href="#0">
                            <i
                              className="fa fa-comment  mr-1"
                              aria-hidden="true"
                            ></i>
                            Comments{" "}
                            {comments && comments.length > 0
                              ? "(" + comments.length + ")"
                              : null}
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="post-cont" style={{ textAlign: "justify" }}>
                      <p>{this.state.BlogDetail.detail}</p>
                    </div>
                  </div>
                </div>
                <Comments comment={comments} language={this.props.language} />
                <PostComment
                  handleSubmit={this.onPostComment}
                  language={this.props.language}
                />
              </div>
            </div>
            <div className="order-1 order-md-2 col-md-4">
              <RecentPosts language={this.props.language} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BlogDetail;
