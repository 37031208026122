import React, { Component } from "react";
import { Link as ScrollLink } from "react-scroll";
import { HashLink } from "react-router-hash-link";
import "./MainHeader.css";
import Video from "./video/video.mp4";
import * as firebase from "firebase";
// import doTranslate from "./helpers/googleTranslateHelper";
class MainHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      header: null,
      languageChangeCount: props.language.languageChangeCount
    };
  }
  componentDidMount() {
    let headerRef = firebase.database().ref("/header");
    headerRef.on("value", snap => {
      if (snap.val()) {
        console.log(snap.val());

        this.setState(
          {
            header: snap.val()
          },
          cb => {
            this.translation();
          }
        );
      }
    });
  }
  componentDidUpdate() {
    if (
      this.state.languageChangeCount != this.props.language.languageChangeCount
    ) {
      this.setState(
        {
          languageChangeCount: this.props.language.languageChangeCount
        },
        cb => {
          this.translation();
        }
      );
    }
  }

  translation() {
    let that = this;
    let header = this.state.header;

    // doTranslate(
    //   header.title,
    //   that.props.language.selectedLanguage.language
    // ).then(trans => {
    //   header.title = trans;
    //   that.setState({ header: header });
    // });
    // doTranslate(
    //   header.subtitle1,
    //   that.props.language.selectedLanguage.language
    // ).then(trans => {
    //   header.subtitle1 = trans;
    //   that.setState({ header: header });
    // });
    // doTranslate(
    //   header.subtitle2,
    //   that.props.language.selectedLanguage.language
    // ).then(trans => {
    //   header.subtitle2 = trans;
    //   that.setState({ header: header });
    // });
    // doTranslate(
    //   header.caption,
    //   that.props.language.selectedLanguage.language
    // ).then(trans => {
    //   header.caption = trans;
    //   that.setState({ header: header });
    // });
  }

  render() {
    const { languageKeywords, selectedLanguage } = this.props.language;
    return (
      <header
        className="header valign"
        data-scroll-index="0"
        data-overlay-dark="5"
      >
        <video className="bg-vid" poster="#" autoPlay loop muted>
          <source src={Video} type="video/mp4" />
        </video>
        {this.state.header && (
          <div className="container">
            <div className="row">
              <div className="full-width text-center caption mt-30">
                <h3>{this.state.header.title}</h3>
                <h1 className="cd-headline clip">
                  <span className="blc ">{this.state.header.subtitle1} </span>
                  <span className="cd-words-wrapper">
                    <b className="is-visible">{this.state.header.subtitle2}</b>
                  </span>
                </h1>
                <p>{this.state.header.caption}</p>
                <a href="#" data-scroll-nav="4" className="butn butn-bord">
                  <span>
                    {languageKeywords.learn_more[selectedLanguage.language]}
                  </span>
                </a>
                <HashLink
                  to="/#contact"
                  className="butn butn-bg"
                  style={{ cursor: "pointer" }}
                >
                  <span>
                    {languageKeywords.contact_us[selectedLanguage.language]}
                  </span>
                </HashLink>
              </div>
            </div>
          </div>
        )}

        <div className="arrow">
          <ScrollLink
            to="about"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
          >
            <i className="fas fa-chevron-down"></i>
          </ScrollLink>
        </div>
      </header>
    );
  }
}

export default MainHeader;
