import React, { Component } from "react";
import * as firebase from "firebase";
import { Link } from "react-router-dom";
import "./Blog.css";

class Blogs extends Component {
  state = {
    blogs: []
  };
  componentDidMount = () => {
    const blogsRef = firebase.database().ref("/blogs");
    blogsRef.on("value", snap => {
      if (snap.val()) {
        this.setState({
          blogs: this.convertToArray(snap.val())
        });
      }
    });
  };
  convertToArray(Objects) {
    const arrayResult = Object.keys(Objects).map(key => {
      return { id: key, data: Objects[key] };
    });
    return arrayResult;
  }
  createMarkupH4(str) {
    return { __html: "<h4>" + str + "</h4>" };
  }
  render() {
    const { languageKeywords, selectedLanguage } = this.props.language;
    const Blogs = this.state.blogs.map(val => (
      <div className="col-sm-4" key={val.id}>
        <img src={val.data.image} />
        <h5
          className="text-left text-sm-center"
          style={{ marginTop: "20px" }}
        >{`${val.data.title.slice(0, 20)}...`}</h5>
        <br />
        <p
          className="text-left text-sm-center"
          style={{ textAlign: "center" }}
        >{`${val.data.detail.slice(0, 50)}...`}</p>
        <p
          className="text-center mb-2"
          style={{ color: "#000", marginTop: "10px" }}
        >
          <Link to={`/blogs/${val.id}`}>
            <b>{languageKeywords.read_more[selectedLanguage.language]}...</b>
          </Link>
        </p>
      </div>
    ));
    return (
      <div className="Blogs">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 mt-4">
              <div
                className="section-head col-sm-12"
                dangerouslySetInnerHTML={this.createMarkupH4(
                  languageKeywords.latest_our_blogs[selectedLanguage.language]
                )}
              >
                {/* <h4>
                                    <span>Latest</span><br />
                                    Our Blogs
                                </h4> */}
              </div>
            </div>
          </div>
        </div>
        <div className="container" style={{ marginBottom: "30px" }}>
          <div className="row">{Blogs}</div>
        </div>
      </div>
    );
  }
}

export default Blogs;
