import React, { Component } from 'react';
import Header from './Header';
import UsecaseImg from '../images/rawpixel-1090772-unsplash.jpg';
import Footer from '../footer';
import UsecasePage from './UsecasePage';
import Navbar from '../Navbar';

class UsecaseContainer extends Component {
    render() {
        return (
            <div className="UsecaseContainer">
                <Header title={"Case Study"} IMG={UsecaseImg} />
                <UsecasePage />

            </div>
        )
    }
}

export default UsecaseContainer;
