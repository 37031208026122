import React, { Component } from 'react';
import SchoolImg from '../images/new_portfolio/schoolmanagement.jpg';
import VenturexCabsImg from '../images/new_portfolio/venturex-cab.jpg';
import AmbassadorCarImg from '../images/new_portfolio/ambassador.jpg';
import VenturexEatImg from '../images/new_portfolio/venturex-eat.jpg';
import WhittingtonImg from '../images/new_portfolio/whittington-cars.jpg';
import './SolutionPage.css';

class SolutionPage extends Component{
    render() {
        return (
            <section id="portfolio" className="portfolio section-padding" data-scroll-index="2">
            <div className="container">
                <div className="row">
    
                    <div className="section-head col-sm-12">
                        <h4>
                            <span>Our</span><br/>
                            Solutions
                        </h4>
                    </div>
                        <div className="col-md-4 items CryptocurrencyExchanges">
                            <div className="item-img">
                                <img src={SchoolImg} style={{height:"230px"}} alt="image"
                                    className="images_size" />
                                <a href="http://venturexlabs.schoolmanagementsoftware.pk/" target="_blank">
                                    <div className="item-img-overlay ">
                                        <h6 style={{float:"left", top:"15px", left:"20px"}}>School Management Software</h6>
                                        <br/>
                                        <br/>
                                        <div className="content">
                                            <ul
                                                style={{float:"left", marginLeft:"34px", lineHeight:"20px", textAlign:"initial" , marginTop:"10px"}}>
                                                <li style={{listStyle:"inside"}}>Asp.net Core, SQL Database</li>
                                                <li style={{listStyle:"inside"}}>Analytics</li>
                                                <li style={{listStyle:"inside"}}>Realtime</li>
                                                <li style={{listStyle:"inside"}}>Optimized Results</li>
                                                <li style={{listStyle:"inside"}}>Reporting</li>
                                                <li style={{backgroundColor:"#940316", display:"inline-block", width:"90px"}}>
                                                    <p style={{color:"#fff", marginLeft:"6px"}}> View Project </p>
                                                </li>
                                            </ul>
    
                                        </div>
    
                                    </div>
                                </a>
                            </div>
                        </div>
                     {/*   <!-- gallery item --> */}
                        <div className="col-md-4 items CoinandICOSolutions">
                            <div className="item-img">
                                <img src={VenturexCabsImg} style={{height:"230px"}} alt="image"
                                    className="images_size" />
                                <a href="http://cab.venturexlabs.com/" target="_blank">
                                    <div className="item-img-overlay ">
                                        <h6 style={{float:"left", top:"15px" , left:"20px"}}>Venturex Cabs</h6>
                                        <br/>
                                        <br/>
                                        <div className="content">
                                            <ul
                                                style={{float:"left", marginLeft:"34px" , lineHeight:"20px", textAlign:"initial", marginTop:"10px"}}>
                                                <li style={{listStyle:"inside"}}>Asp.net Core, SQL Database</li>
                                                <li style={{listStyle:"inside"}}>Realtime</li>
                                                <li style={{listStyle:"inside"}}>Android</li>
                                                <li style={{listStyle:"inside"}}>Firebase</li>
                                                <li style={{listStyle:"inside"}}>Push Notifications</li>
                                                <li style={{listStyle:"inside"}}>Geo Locations</li>
                                                <li style={{backgroundColor:"#940316", display:"inline-block", width:"90px"}}>
                                                    <p style={{color:"#fff", marginLeft:"6px"}}> View Project </p>
                                                </li>
                                            </ul>
    
                                        </div>
    
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4 items CryptocurrencyExchanges">
                            <div className="item-img">
                                <img src={WhittingtonImg} style={{height:"230px"}} alt="image"
                                    className="images_size" />
                                <a href="http://demoexchange.venturexlabs.com/" target="_blank">
                                    <div className="item-img-overlay ">
                                        <h6 style={{float:"left", top:"15px", left:"20px"}}>Whittington Cars</h6>
                                        <br/>
                                        <br/>
                                        <div className="content">
                                            <ul
                                                style={{float:"left", marginLeft:"34px", lineHeight:"20px", textAlign:"initial" , marginTop:"10px"}}>
                                                <li style={{listStyle:"inside"}}>Asp.net Core, SQL Database</li>
                                                <li style={{listStyle:"inside"}}>Geo Location</li>
                                                <li style={{listStyle:"inside"}}>Realtime</li>
                                                <li style={{listStyle:"inside"}}>Android</li>
                                                <li style={{listStyle:"inside"}}>Push Notifications</li>
                                                <li style={{listStyle:"inside"}}>Firebase</li>
                                                <li style={{backgroundColor:"#940316", display:"inline-block", width:"90px"}}>
                                                    <p style={{color:"#fff", marginLeft:"6px"}}> View Project </p>
                                                </li>
                                            </ul>
    
                                        </div>
    
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4 items CustomizeBlockchainSolutions">
                            <div className="item-img">
                                <img src={AmbassadorCarImg} style={{height:"230px"}} alt="image"
                                    className="images_size" />
                                <a href="https://ambassadorcars-venturex.firebaseapp.com/" target="_blank">
                                    <div className="item-img-overlay ">
                                        <h6 style={{float:"left", top:"15px", left:"20px"}}>Ambassador Cars</h6>
                                        <br/>
                                        <br/>
                                        <div className="content">
                                            <ul
                                                style={{float:"left", marginLeft:"34px", lineHeight:"20px", textAlign:"initial", marginTop:"10px"}}>
                                                <li style={{listStyle:"inside"}}>ASP.net Core, SQL Database</li>
                                                <li style={{listStyle:"inside"}}>Geo Location</li>
                                                <li style={{listStyle:"inside"}}>Realtime</li>
                                                <li style={{listStyle:"inside"}}>Android</li>
                                                <li style={{listStyle:"inside"}}>Push Notifications</li>
                                                <li style={{listStyle:"inside"}}>Firebase</li>
                                                <li style={{backgroundColor:"#940316", display:"inline-block", width:"90px"}}>
                                                    <p style={{color:"#fff", marginLeft:"6px"}}> View Project </p>
                                                </li>
                                            </ul>
    
                                        </div>
    
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4 items CryptocurrencyExchanges">
                            <div className="item-img">
                                <img src={VenturexEatImg} style={{height:"230px"}} alt="image"
                                    className="images_size" />
                                <a href="http://142.93.43.200/" target="_blank">
                                    <div className="item-img-overlay ">
                                        <h6 style={{float:"left", top:"15px", left:"20px"}}>Venturex Eat</h6>
                                        <br/>
                                        <br/>
                                        <div className="content">
                                            <ul
                                                style={{float:"left", marginLeft:"34px", lineHeight:"20px", textAlign:"initial" , marginTop:"10px"}}>
                                                <li style={{listStyle:"inside"}}>Asp.net Core, SQL Database</li>
                                                <li style={{listStyle:"inside"}}>Geo Location</li>
                                                <li style={{listStyle:"inside"}}>Realtime</li>
                                                <li style={{listStyle:"inside"}}>Android</li>
                                                <li style={{listStyle:"inside"}}>Push Notifications</li>
                                                <li style={{listStyle:"inside"}}>Firebase</li>
                                                <li style={{backgroundColor:"#940316", display:"inline-block", width:"90px"}}>
                                                    <p style={{color:"#fff", marginLeft:"6px"}}> View Project </p>
                                                </li>
                                            </ul>
    
                                        </div>
    
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="clear-fix"></div>
    
                    </div>
            </div>
        </section>
        );
    }
}

export default SolutionPage;
