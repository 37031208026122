import React from "react";

export default ({ width, height, fill, bg }) => {
  return (
    <svg
      style={{ backgroundColor: bg }}
      fill={fill}
      width={width}
      height={height}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 454.44 454.35"
    >
      <g>
        <path
          class="st0"
          d="M304.23,316.83c2.54,0,4.77,0,7,0c20.51,0,41.03,0.13,61.54-0.09c6.17-0.07,8.2,3.73,7.25,8.81
		c-1.77,9.42-7.88,15.63-17.49,17.15c-28.49,4.52-57.01,8.87-85.51,13.4c-12.41,1.98-24.82,3.76-37.42,3.49
		c-30.28-0.65-58.63-7.87-84.35-24.48c-5.17-3.34-11.1-5.51-16.74-8.09c-1.29-0.59-2.2-0.93-2.19-2.68
		c0.07-24.9,0.06-49.81,0.08-74.71c0-0.3,0.08-0.6,0.14-0.96c0.26-0.11,0.52-0.34,0.79-0.34c17.12,0.06,34.29-1,51.26,2.4
		c37.14,7.43,67.88,25.34,91.14,55.54c2.74,3.56,5.45,7.13,8.09,10.77c4.06,5.6,3.65,12.08-0.95,16.34
		c-4.47,4.14-10.91,4.09-16.14-0.17c-6.8-5.55-13.55-11.15-20.36-16.68c-3.2-2.59-6.35-2.96-9-1.25c-4.5,2.89-5.02,8.45-0.89,11.92
		c7.5,6.3,15.02,12.59,22.74,18.6c8.66,6.74,20.7,7.04,29.84,1.13c9.37-6.06,13.88-16.96,11.52-27.87
		C304.46,318.47,304.4,317.86,304.23,316.83z"
        />
        <path
          class="st0"
          d="M300.48,254.37c-43.92,0.03-79.87-35.73-79.87-79.46c0-44.38,35.65-80.21,79.78-80.18
		c43.97,0.02,79.83,35.73,79.83,79.49C380.23,218.63,344.71,254.33,300.48,254.37z M366.05,174.8
		c0.24-35.64-29.03-65.42-64.61-65.73c-36.76-0.32-66.22,28.56-66.54,65.24c-0.31,35.98,29.23,65.69,65.44,65.82
		C336.23,240.27,365.82,210.85,366.05,174.8z"
        />
        <path
          class="st0"
          d="M121.75,290.87c0,16.33,0.01,32.66,0,48.99c0,5.12-1.41,6.52-6.55,6.53c-11.53,0.01-23.06,0.02-34.6,0
		c-4.98-0.01-6.41-1.43-6.41-6.32c-0.01-32.66-0.01-65.31,0-97.97c0-4.88,1.52-6.43,6.37-6.44c11.63-0.01,23.27-0.02,34.9,0
		c4.7,0.01,6.28,1.57,6.28,6.24C121.75,258.22,121.75,274.54,121.75,290.87z"
        />
        <path
          class="st0"
          d="M377.27,302.15c-1.56,0-2.76,0-3.95,0c-24.9,0-49.79-0.03-74.69,0.05c-2.04,0.01-3.41-0.59-4.66-2.17
		c-2.81-3.57-5.78-7.01-9.05-10.94c1.4-0.1,2.16-0.2,2.93-0.2c22.35-0.01,44.69-0.02,67.04,0.01
		C364.59,288.9,372.71,293.64,377.27,302.15z"
        />
        <path
          class="st0"
          d="M295.08,124.13c0,3.07,0.11,5.92-0.07,8.75c-0.05,0.74-0.85,1.79-1.56,2.08c-7.73,3.11-12.67,8.48-13.58,16.93
		c-1,9.32,2.99,16.38,10.97,20.84c3.46,1.93,4.56,4.03,4.34,7.84c-0.35,6.3-0.1,12.64-0.1,19.31c-4.53-1.52-6.95-4.66-8.27-8.79
		c-0.72-2.26-1.82-4.09-4.27-4.73c-4.6-1.21-8.53,2.3-8.01,7.23c0.65,6.1,3.96,10.74,8.85,14.03c3.49,2.35,7.51,3.93,11.6,6.02
		c0,3.3,0,7.16,0,10.99c-18.25,3.09-42.86-21.5-45.21-44.24C246.6,149.64,270.22,125.93,295.08,124.13z"
        />
        <path
          class="st0"
          d="M306.3,224.93c0-4.03,0-7.78,0-11.72c1.5-0.5,3.03-0.94,4.52-1.51c8.6-3.28,13.47-9.6,14.83-18.61
		c1.26-8.41-1.63-15.31-8.29-20.47c-2.88-2.23-6.22-3.85-9.27-5.87c-0.79-0.53-1.78-1.52-1.81-2.33
		c-0.16-5.49-0.08-10.98,0.64-16.88c0.81,1.14,1.73,2.22,2.4,3.44c0.58,1.06,0.83,2.29,1.27,3.43c1.37,3.54,3.12,4.6,6.53,4.01
		c2.82-0.48,4.97-2.95,4.98-6.11c0.02-5.97-2.82-10.51-7.51-13.97c-1.22-0.9-2.55-1.95-3.97-2.23c-4.09-0.8-5.04-3.25-4.47-6.97
		c0.24-1.58,0.04-3.23,0.04-4.83c21.57-1.12,44.59,23.64,45.18,48.59C352.11,203.86,327.59,223.36,306.3,224.93z"
        />
        <path
          class="st0"
          d="M306.35,180.9c5.12,2,7.43,5.01,7.3,9.41c-0.14,4.77-2.56,7.94-7.3,9.42
		C306.35,193.44,306.35,187.2,306.35,180.9z"
        />
        <path
          class="st0"
          d="M295,147.51c0,4.45,0,8.5,0,12.6C290.49,157.7,290.39,151.7,295,147.51z"
        />
        <circle class="st0" cx="301.24" cy="174.93" r="51.7" />
      </g>
    </svg>
  );
};
