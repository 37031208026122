import React, { Component } from "react";
import ParisMap from "./images/map_paris.jpg";
import LondonMap from "./images/map_London.jpg";
import BG1 from "./images/paris.png";
import BG2 from "./images/uk.png";
import "./map.css";
import $ from "jquery";

class Map extends Component {
  componentDidMount = () => {
    $(document).ready(function() {
      $(".carousel").carousel({
        interval: 5000
      });
    });
  };
  render() {
    return (
      <section
        className="contact"
        data-scroll-index="10"
        style={{ padding: "0px" }}
      >
        <div id="myCarousel1" className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators">
            <li
              data-target="#myCarousel1"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#myCarousel1" data-slide-to="1"></li>
          </ol>
          <div className="carousel-inner" style={{ position: "relative" }}>
            <div className="carousel-item carousel-image-1 active">
              <div className="row">
                <div
                  className="col-lg-2"
                  id="uk"
                  style={{
                    padding: "3%",
                    color: "white",
                    backgroundRepeat: "round",
                    backgroundImage: `url(${BG1})`
                  }}
                >
                  <div className="TT">
                    <h6>
                      <b> LONDON, UK </b>
                    </h6>

                    <div className="d-flex mt-5">
                      <i
                        style={{
                          fontSize: "10px",
                          padding: "0px !important"
                        }}
                        className="far fa-envelope"
                      ></i>
                      <p
                        className="d-inline-block"
                        style={{
                          paddingLeft: "10px",
                          fontSize: "12px",
                          color: "white",
                          lineHeight: "1"
                        }}
                      >
                        MAIL <br /> info@venturexlabs.com
                      </p>
                    </div>
                    <div className="d-flex mt-4">
                      <i
                        style={{ fontSize: "10px" }}
                        className="fas fa-location-arrow"
                      ></i>
                      <p
                        className="d-inline-block"
                        style={{
                          paddingLeft: "10px",
                          fontSize: "12px",
                          color: "white",
                          lineHeight: "1.2"
                        }}
                      >
                        Location
                        <br />
                        Kemp House 160 City Road London EC1V 2NX United Kingdom
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-10 map" id="l_Map">
                  <a
                    href="https://goo.gl/maps/oAD3ADwLxWU84Dxb7"
                    target="_blank"
                  >
                    <img
                      src={LondonMap}
                      style={{ width: "102%", filter: "grayscale(85%)" }}
                      className="images-responsive"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="carousel-item carousel-image-2">
              <div className="row">
                <div
                  className="col-lg-2"
                  id="paris"
                  style={{
                    padding: "3%",
                    color: "white",
                    backgroundRepeat: "round",
                    backgroundImage: `url(${BG2})`
                  }}
                >
                  <div className="TT">
                    <h6>
                      <b>PARIS, FRANCE</b>
                    </h6>

                    <div className="d-flex mt-5">
                      <i
                        style={{
                          fontSize: "10px",
                          padding: "0px !important"
                        }}
                        className="far fa-envelope"
                      ></i>
                      <p
                        className="d-inline-block"
                        style={{
                          paddingLeft: "10px",
                          fontSize: "12px",
                          color: "white",
                          lineHeight: "1.2"
                        }}
                      >
                        MAIL
                        <br /> info@venturexlabs.com
                      </p>
                    </div>
                    <div className="d-flex mt-4">
                      <i
                        style={{ fontSize: "10px" }}
                        className="fas fa-location-arrow"
                      ></i>
                      <p
                        className="d-inline-block"
                        style={{
                          paddingLeft: "10px",
                          fontSize: "12px",
                          color: "white",
                          lineHeight: "1"
                        }}
                      >
                        LOCATION <br /> 12 Place de la Defense 92974 Paris La,
                        Defense France.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-10 map" id="p_Map">
                  <a
                    href="https://www.google.com/maps/place/12+Place+de+la+DÃ©fense,+92400+Courbevoie,+France/data=!4m2!3m1!1s0x47e66504589739b9:0x7ac7a35ddb769985?sa=X&amp;ved=2ahUKEwj9-4fmle3hAhVxyoUKHS0dD0cQ8gEwAHoECAkQAQ"
                    target="_blank"
                  >
                    <img
                      src={ParisMap}
                      style={{ width: "102%", filter: "grayscale(85%)" }}
                      className="images-responsive"
                    />
                  </a>
                </div>
              </div>
            </div>
            <a
              class="carousel-control-prev"
              href="#myCarousel1"
              data-slide="prev"
              style={{ opacity: 0.7 }}
            >
              <i
                class="fas fa-chevron-left"
                style={{ color: "rgb(80,80,80)", fontSize: "30px" }}
              ></i>
            </a>
            <a
              class="carousel-control-next"
              href="#myCarousel1"
              data-slide="next"
              style={{ opacity: 0.7, transform: "rotate(-180deg)", zIndex: 10 }}
            >
              <i
                class="fas fa-chevron-left"
                style={{ color: "rgba(80,80,80)", fontSize: "30px" }}
              ></i>
            </a>
          </div>
        </div>
      </section>
    );
  }
}

export default Map;
