import React, { Component } from "react";

class PostComment extends Component {
  state = {
    name: "",
    email: "",
    comment: ""
  };
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.handleSubmit(this.state, () => {
      this.setState({ name: "", email: "", comment: "" });
    });
  };
  render() {
    const { languageKeywords, selectedLanguage } = this.props.language;
    return (
      <div className="PostComment">
        <div className="comment-form">
          <div className="title-g mb-50 ">
            <h3 className="mt-4 mb-4">
              {languageKeywords.post_a_comment[selectedLanguage.language]}
            </h3>
          </div>

          <form onSubmit={this.handleSubmit}>
            <div className="controls">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group has-error has-danger">
                    <input
                      required
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder={
                        languageKeywords.name[selectedLanguage.language]
                      }
                      style={{ width: "102%", height: "40px" }}
                      type="text"
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group has-error has-danger">
                    <input
                      required
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="Email"
                      style={{ height: "40px" }}
                      type="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group has-error has-danger">
                    <textarea
                      required
                      className="form-control"
                      cols="20"
                      id="message"
                      name="comment"
                      placeholder={
                        languageKeywords.message[selectedLanguage.language]
                      }
                      rows="6"
                      style={{ width: "100%", minHeight: "50px" }}
                      type="text"
                      value={this.state.comment}
                      onChange={this.handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12 text-right">
                  <button
                    type="submit"
                    name="post"
                    className="disabled"
                    style={{
                      backgroundColor: "#7A0405",
                      color: "white",
                      borderColor: "#7A0405",
                      height: "40px",
                      width: "150px"
                    }}
                  >
                    <span>
                      {languageKeywords.post_comment[selectedLanguage.language]}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default PostComment;
