import React, { Component, Fragment } from "react";
import "./Contact.css";
import * as firebase from "firebase";
import axios from "axios";
import swal from "sweetalert";

class Contact extends Component {
  state = {
    name: "",
    number: "",
    email: "",
    subject: "",
    message: "",
    to: "",
    show: false
  };
  componentDidMount = e => {
    const mailRef = firebase.database().ref("/mailto");
    mailRef.on("value", snap => {
      if (snap.val()) {
        this.setState({ to: snap.val().mail });
      }
    });
  };
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  createMarkupH4(str) {
    return { __html: "<h4>" + str + "</h4>" };
  }
  handleSubmit = async e => {
    e.preventDefault();
    try {
      let messageRef = firebase.database().ref("/message");
      messageRef.push(this.state);
      axios
        .post("https://email-server-fse.herokuapp.com/send", {
          to: this.state.to,
          from: `${this.state.name} Sent you Contact message at Venturexlabs`,
          subject: `${this.state.subject}`,
          text: `Name: ${this.state.name},
                Email: ${this.state.email},
                Number:${this.state.number},
                Subject:${this.state.subject},
                Message:${this.state.message}`
        })
        .then(res => {
          swal({
            title: "Thank You!",
            text: "your message has been sent successfully.",
            type: "success",
            timer: 3000
          });
          this.setState({
            name: "",
            number: "",
            email: "",
            subject: "",
            message: ""
          });
        });
    } catch (err) {
      console.log(err);
    }
  };
  render() {
    const { languageKeywords, selectedLanguage } = this.props.language;
    return (
      <Fragment id="contact">
        <section
          id="contact"
          href="/#contact"
          className="contact"
          style={{ backgroundColor: "#f7f7f7", position: "relative" }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="offset-lg-3 col-lg-6 offset-lg-3 contact-form">
                <form id="myForm" onSubmit={this.handleSubmit}>
                  <div className="messages"></div>
                  <div className="controls">
                    <div className="row">
                      <div
                        className="section-head col-sm-12"
                        dangerouslySetInnerHTML={this.createMarkupH4(
                          languageKeywords.get_in_touch[
                            selectedLanguage.language
                          ]
                        )}
                      >
                        {/* <h4>
                        <span>Get</span>
                        <br />
                        In Touch
                      </h4> */}
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            autoComplete="off"
                            id="form_name"
                            name="name"
                            placeholder={
                              languageKeywords.name[selectedLanguage.language]
                            }
                            required="required"
                            type="text"
                            value={this.state.name}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            autoComplete="off"
                            id="form_number"
                            name="number"
                            placeholder={
                              languageKeywords.phone_number[
                                selectedLanguage.language
                              ]
                            }
                            required="required"
                            type="number"
                            value={this.state.number}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            autoComplete="off"
                            id="form_email"
                            name="email"
                            placeholder="Email"
                            required="required"
                            type="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            autoComplete="off"
                            id="form_subject"
                            name="subject"
                            placeholder={
                              languageKeywords.subject[
                                selectedLanguage.language
                              ]
                            }
                            required="required"
                            type="text"
                            value={this.state.subject}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea
                            autoComplete="off"
                            cols="20"
                            rows="4"
                            id="form_message"
                            name="message"
                            placeholder="Message"
                            required="required"
                            type="text"
                            value={this.state.message}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 text-center">
                        <br />
                        <button
                          type="submit"
                          style={{ height: "60px", borderRadius: "5px" }}
                        >
                          <span>
                            {
                              languageKeywords.send_message[
                                selectedLanguage.language
                              ]
                            }
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default Contact;
