import React from "react";

export default ({ width, height, fill, bg }) => {
  return (
    <svg
      style={{ backgroundColor: bg }}
      fill={fill}
      width={width}
      height={height}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 454.44 454.35"
    >
      <g>
        <path
          class="st0"
          d="M54.02,146.41c-19.99,0-38.96,0-58.33,0c0-23.73,0-47.11,0-70.92c154.25,0,308.46,0,463.07,0
   c0,23.46,0,46.84,0,70.61c-31.04,0-61.95,0-92.86,0c2.2-10.51,15.11-22.34,37.69-35.48c-3.78,0-7.61,0.38-11.34-0.07
   c-16.76-2.01-30.21,4.8-42.03,15.7c-12.86,11.85-21.36,26.54-22.09,44.01c-1.14,27.28-0.78,54.63-0.86,81.96
   c-0.11,36.83-0.01,73.66-0.05,110.49c-0.01,11.15-5,16.12-16.25,16.13c-82.99,0.04-165.99,0.05-248.98,0.02
   c-11.95,0-16.79-4.82-16.79-16.59c-0.03-60.83-0.03-121.65,0.01-182.48c0.01-9.8,2.44-19.1,6.45-28.01
   C52.31,150.29,52.96,148.8,54.02,146.41z M274.19,301.59c-12.85,4.32-19.65,10.05-23.48,20.26c-1.22,3.25-2.77,4.17-6.11,4.15
   c-27.49-0.13-54.98-0.07-82.47-0.07c-14.82,0-29.63,0-44.62,0c-3.37-10.77-9.77-18.77-20.37-22.6c-3.22-1.16-3.79-2.79-3.78-5.8
   c0.08-41.49-0.17-82.97,0.25-124.46c0.08-8,1.59-16.38,4.35-23.88c6.36-17.34,17.52-29.72,37.62-30.88c4.55-0.26,7.3-3.83,7-8.13
   c-0.29-4.17-3.48-7.09-8.18-7.01c-12.16,0.21-22.79,4.66-31.8,12.64c-17.22,15.25-24.24,35.14-24.38,57.51
   c-0.28,44.48-0.08,88.97-0.05,133.45c0.01,6.5,2.29,8.92,8.78,9.67c8.74,1.01,14.71,6.78,15.89,15.36
   c1.04,7.53,3.1,9.41,10.66,9.41c46.48,0.02,92.97,0.02,139.45,0c7.68,0,9.71-1.77,10.93-9.24c1.59-9.72,7.25-14.86,17.15-15.57
   c5.91-0.42,8.27-2.89,8.44-8.84c0.05-1.83,0.01-3.67,0.01-5.5c0-43.65-0.31-87.31,0.12-130.95c0.23-22.95,7.81-42.46,29.93-53.58
   c4.1-2.06,5.33-6.45,3.48-10.18c-1.8-3.63-6.11-5.14-10.24-3.35c-10.03,4.35-18.09,11.16-24.4,20.05
   c-10.54,14.84-14.23,31.65-14.2,49.55c0.05,31.32,0.04,62.64,0.05,93.97C274.19,278.9,274.19,290.21,274.19,301.59z M141.74,176.6
   c0.13,24.83,19.64,43.94,44.74,43.84c23.39-0.1,42.97-20.03,42.91-43.66c-0.07-24.63-19.61-44.26-43.97-44.16
   C161.25,132.71,141.61,152.49,141.74,176.6z M185.62,283.58c9.44,0.01,17.21-7.62,17.33-17.03c0.13-9.53-7.84-17.51-17.45-17.48
   c-9.41,0.03-17.2,7.7-17.34,17.07C168.02,275.68,175.93,283.57,185.62,283.58z"
        />
      </g>
    </svg>
  );
};
