import React from "react";

export default ({ width, height, fill, bg }) => {
  return (
    <svg
      style={{ backgroundColor: bg }}
      fill={fill}
      width={width}
      height={height}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 454.44 454.35"
    >
      <g>
        <path
          class="st0"
          d="M289.63,245.77c0,6.72-5.45,12.17-12.17,12.17c-6.72,0-12.17-5.45-12.17-12.17s5.45-12.17,12.17-12.17
		C284.18,233.6,289.63,239.05,289.63,245.77z"
        />
        <path
          class="st0"
          d="M103.66,219.69c22.39-15.01,51.22-16.45,74.99-3.76s38.61,37.46,38.58,64.41c0,2-0.06,4-0.25,5.93h135.04
		c10.33-0.03,18.69-8.39,18.72-18.72v-70.52H103.66V219.69z M277.46,221.06c9.18,0,17.59,5.1,21.84,13.23
		c5.26-10.06,16.7-15.23,27.72-12.52c11.02,2.71,18.77,12.59,18.77,23.94c0,11.35-7.75,21.23-18.77,23.94
		c-11.02,2.71-22.47-2.46-27.72-12.52c-5.6,10.72-18.15,15.79-29.63,11.97c-11.47-3.82-18.48-15.39-16.55-27.33
		C255.05,229.83,265.36,221.06,277.46,221.06z"
        />
        <path
          class="st0"
          d="M352.03,113.48H122.38c-10.33,0.03-18.69,8.4-18.72,18.72v12.48h267.09V132.2
		C370.74,121.86,362.37,113.48,352.03,113.48z"
        />
        <path class="st0" d="M103.66,157.16h267.09v27.46H103.66V157.16z" />
        <path
          class="st0"
          d="M144.22,219.81c-33.43,0-60.53,27.1-60.53,60.53c0,33.43,27.1,60.53,60.53,60.53s60.53-27.1,60.53-60.53
		C204.72,246.93,177.64,219.84,144.22,219.81z M150.46,304.93c0,3.45-2.79,6.24-6.24,6.24s-6.24-2.79-6.24-6.24v-29.08
		c0-3.45,2.79-6.24,6.24-6.24s6.24,2.79,6.24,6.24V304.93z M150.46,260c0,3.45-2.79,6.24-6.24,6.24s-6.24-2.79-6.24-6.24v-4.12
		c0-3.45,2.79-6.24,6.24-6.24s6.24,2.79,6.24,6.24V260z"
        />
        <path
          class="st0"
          d="M333.31,245.77c0,6.72-5.45,12.17-12.17,12.17c-6.72,0-12.17-5.45-12.17-12.17s5.45-12.17,12.17-12.17
		C327.86,233.6,333.31,239.05,333.31,245.77z"
        />
      </g>
    </svg>
  );
};
