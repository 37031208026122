import React, { Component } from "react";
import * as firebase from "firebase";
import logo1 from "./images/techs/logo1.jpg";
import logo2 from "./images/techs/logo2.jpg";
import logo3 from "./images/techs/logo3.jpg";
import logo4 from "./images/techs/logo4.jpg";
import logo5 from "./images/techs/logo5.jpg";
import logo6 from "./images/techs/logo6.jpg";
import logo7 from "./images/techs/logo7.jpg";
import logo8 from "./images/techs/logo8.jpg";
import logo9 from "./images/techs/logo9.jpg";
import logo10 from "./images/techs/logo10.jpg";
import logo11 from "./images/techs/logo11.jpg";
import logo12 from "./images/techs/logo12.jpg";
import logo13 from "./images/techs/logo13.jpg";
import logo14 from "./images/techs/logo14.jpg";
import logo15 from "./images/techs/logo15.jpg";
import logo16 from "./images/techs/logo16.jpg";
import logo17 from "./images/techs/logo17.jpg";
import logo18 from "./images/techs/logo18.jpg";
import logo19 from "./images/techs/logo19.jpg";
import logo20 from "./images/techs/logo20.jpg";
import logo21 from "./images/techs/python-logo.png";
import logo22 from "./images/techs/bigData.png";
import logo23 from "./images/techs/laravel.png";
import "./Clients.css";

class Clients extends Component {
  state = {
    Projects: []
  };
  componentDidMount = () => {
    const projectRef = firebase.database().ref("/projects");
    projectRef.on("value", snap => {
      if (snap.val()) {
        this.setState({ Projects: snap.val() });
      }
    });
  };
  render() {
    const { clients, month, offices } = this.state.Projects;
    return (
      <div className="Clients">
        <div style={{ backgroundColor: "#070E20" }}>
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex justify-content-center align-items-center">
                <h4
                  style={{
                    marginTop: "60px",
                    fontSize: "40px",
                    fontWeight: "bold",
                    color: "white",
                    marginTop: "30px"
                  }}
                >
                  Projects
                </h4>
              </div>
              <div className="mt-5 col-12 d-inline-flex justify-content-center align-items-center">
                <div
                  className="col-md-2 col-3 py-4 px-sm-3 px-2"
                  style={{
                    borderBottomLeftRadius: "6px",
                    borderTopLeftRadius: "6px",
                    border: "1px solid white",
                    color: "white",
                    textAlign: "center"
                  }}
                >
                  <h3>
                    {" "}
                    <b>{month}</b>{" "}
                  </h3>
                  <br />
                  <h6>
                    {" "}
                    MONTHS IN <br /> OPERATIONS{" "}
                  </h6>
                </div>
                <div
                  className="col-md-2 col-3 py-4 px-sm-3 px-2"
                  style={{
                    border: "1px solid white",
                    color: "white",
                    textAlign: "center"
                  }}
                >
                  <h3>
                    {" "}
                    <b>{clients}</b>{" "}
                  </h3>
                  <br />
                  <h6>
                    {" "}
                    LOYAL <br /> CLIENTS{" "}
                  </h6>
                </div>
                <div
                  className="col-md-2 col-3 py-4 px-sm-3 px-2"
                  style={{
                    borderBottomRightRadius: "6px",
                    borderTopRightRadius: "6px",
                    border: "1px solid white",
                    color: "white",
                    textAlign: "center"
                  }}
                >
                  <h3>
                    {" "}
                    <b>{offices}</b>{" "}
                  </h3>
                  <br />
                  <h6>
                    {" "}
                    OFFICES <br /> GLOBALY{" "}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid align-content">
            <div className="marquee my-5">
              <marquee behavior="scroll" direction="left" scrollamount="10">
                <div className="marquee-content" style={{ display: "flex" }}>
                  <img
                    src={logo1}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                  <img
                    src={logo2}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                  <img
                    src={logo3}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                  <img
                    src={logo4}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                  <img
                    src={logo5}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                  <img
                    src={logo6}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                  <img
                    src={logo7}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                  <img
                    src={logo8}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                  <img
                    src={logo9}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                  <img
                    src={logo10}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                  <img
                    src={logo11}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                  <img
                    src={logo12}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                  <img
                    src={logo13}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                  <img
                    src={logo14}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                  <img
                    src={logo15}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                  <img
                    src={logo16}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                  <img
                    src={logo17}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                  <img
                    src={logo18}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                  <img
                    src={logo19}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                  <img
                    src={logo20}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                  <img
                    src={logo21}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                  <img
                    src={logo22}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                  <img
                    src={logo23}
                    className="mx-3"
                    style={{ borderRadius: "5px" }}
                    alt="image"
                  />
                </div>
              </marquee>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Clients;
