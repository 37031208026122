import React, { Component } from "react";
import BG from "./images/image_bg.jpg";
import Icofont from "react-icofont";
import * as firebase from "firebase";
// import doTranslate from "./helpers/googleTranslateHelper";

class Solutions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exchange: true,
      wallets: false,
      blockchain: false,
      quorum: false,
      solution: null,
      activetool: 0,
      languageChangeCount: props.language.languageChangeCount
    };
  }
  componentDidMount = () => {
    const SolutionRef = firebase.database().ref("/solutions");
    SolutionRef.on("value", snap => {
      if (snap.val()) {
        // console.log(snap.val());
        let solution = snap.val();
        solution.tools = this.convertToArray(solution.tools);
        this.setState({ solution: solution, activetool: 0 }, cb => {
          this.translation();
          this.translationTools();
        });
      }
    });
  };
  convertToArray(Objects) {
    const arrayResult = Object.keys(Objects).map(key => {
      return { id: key, data: Objects[key] };
    });
    return arrayResult;
  }
  componentDidUpdate() {
    if (
      this.state.languageChangeCount != this.props.language.languageChangeCount
    ) {
      // console.log("Language Change Detected");
      this.setState(
        {
          languageChangeCount: this.props.language.languageChangeCount
        },
        cb => {
          this.translation();
          this.translationTools();
        }
      );
    }
  }
  translation() {
    let that = this;
    let solution = this.state.solution;

    // doTranslate(
    //   solution.top_paragraph,
    //   that.props.language.selectedLanguage.language
    // ).then(trans => {
    //   solution.top_paragraph = trans;
    //   that.setState({ solution });
    // });

    // doTranslate(solution.bottom_paragraph, that.props.language.selectedLanguage.language).then(trans => {
    //     solution.bottom_paragraph = trans;
    //     that.setState({ solution });
    // })
  }
  translationTools() {
    let that = this;
    let solution = this.state.solution;
    for (let index = 0; index < solution.tools.length; index++) {
      if (solution.tools[index]) {
        // doTranslate(
        //   solution.tools[index].data.title,
        //   that.props.language.selectedLanguage.language
        // ).then(trans => {
        //   solution.tools[index].data.title = trans;

        //   this.setState({ solution });
        // });
        // doTranslate(
        //   solution.tools[index].data.paragraph,
        //   that.props.language.selectedLanguage.language
        // ).then(trans => {
        //   solution.tools[index].data.paragraph = trans;

        //   this.setState({ solution });
        // });
      }
    }
  }
  createMarkupH6(str) {
    // console.log(str);

    return { __html: '<h6 style="color: #940316">' + str + "</h6>" };
  }
  createMarkupP(str) {
    // console.log(str);

    return { __html: '<p style="text-align: justify">' + str + "</p>" };
  }
  render() {
    const { exchange, wallets, blockchain, quorum } = this.state;
    const { languageKeywords, selectedLanguage } = this.props.language;
    return (
      <section
        id="services"
        className="serv-tabs section-padding"
        style={{
          backgroundImage: `url(${BG})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat"
        }}
        data-overlay-dark="4"
        data-background="images/image-bg.jpg"
        data-scroll-index="4"
      >
        <div className="container">
          <div className="row">
            <div className="section-head col-12">
              <h4>
                <span>Blockchain</span>
                <br />
                Solutions
              </h4>
            </div>
            <div className="col-lg-12">
              <div className="item bord solutions">
                {this.state.solution && (
                  <p style={{ textAlign: "justify" }}>
                    {this.state.solution.top_paragraph}
                  </p>
                )}
              </div>
            </div>
            <div className="tabs col-12">
              <div className="tabs-icon row">
                {this.state.solution &&
                  this.state.solution.tools &&
                  this.state.solution.tools.map((item, key) => {
                    return (
                      <div
                        className={`col-md-3 col-sm-6 col-11 item ${key ==
                          this.state.activetool && "active"}`}
                        key={key}
                        onClick={() => {
                          this.setState({ activetool: key });
                        }}
                      >
                        <div className="hover_effect1">
                          <span className="icon">
                            <Icofont icon={item.data.icon} />
                          </span>
                          <br />
                          <span
                            dangerouslySetInnerHTML={this.createMarkupH6(
                              item.data.title
                            )}
                          >
                            {/* <h6 style={{ color: "#940316" }} dangerouslySetInnerHTML={this.createMarkup(item.data.title)}> </h6> */}
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <br />
              <br />
              <br />
              <div className="content">
                {this.state.solution &&
                  this.state.solution.tools &&
                  this.state.solution.tools.map((item, key) => {
                    return (
                      <div
                        className={`tab-item ${this.state.activetool == key &&
                          "curent"}`}
                      >
                        <div className="row">
                          <div className="col-lg-7">
                            <div className="item solutions">
                              {/*   <!-- <img src="images/tools.PNG" className="images-responsive tools"  /> --> */}
                              {key == 0 && (
                                <div>
                                  <table
                                    className="table table-responsive"
                                    style={{
                                      color: "white",
                                      width: "100%",
                                      border: "1px solid white",
                                      borderRadius: "7px"
                                    }}
                                  >
                                    <tbody>
                                      <tr>
                                        <th
                                          colSpan="5"
                                          className="borderStyle"
                                          style={{ borderRadius: "10px" }}
                                        >
                                          {" "}
                                          {
                                            languageKeywords
                                              .tools_and_technologies[
                                              selectedLanguage.language
                                            ]
                                          }{" "}
                                        </th>
                                      </tr>

                                      <tr>
                                        <td className="borderStyle">
                                          <img
                                            loading="lazy"
                                            src="images/tozes.png"
                                            style={{
                                              width: "20px",
                                              height: "20px"
                                            }}
                                          />
                                          <br />
                                          <span style={{ marginTop: "3px" }}>
                                            {" "}
                                            TEZOS{" "}
                                          </span>
                                        </td>

                                        <td className="borderStyle">
                                          <img
                                            loading="lazy"
                                            src="images/ethereum.png"
                                            style={{
                                              width: "20px",
                                              height: "20px"
                                            }}
                                          />
                                          <br />{" "}
                                          <span style={{ marginTop: "3px" }}>
                                            {" "}
                                            ETHEREUM
                                          </span>
                                        </td>

                                        <td className="borderStyle">
                                          <img
                                            src="images/ripple.png"
                                            style={{
                                              width: "20px",
                                              height: "20px"
                                            }}
                                          />
                                          <br />{" "}
                                          <span style={{ marginTop: "3px" }}>
                                            {" "}
                                            RIPPLE{" "}
                                          </span>
                                        </td>

                                        <td className="borderStyle">
                                          <img
                                            loading="lazy"
                                            src="images/shadowcase.png"
                                            style={{
                                              width: "20px",
                                              height: "20px"
                                            }}
                                          />
                                          <br />{" "}
                                          <span style={{ marginTop: "3px" }}>
                                            SHADOWCASH{" "}
                                          </span>
                                        </td>

                                        <td className="borderStyle">
                                          <img
                                            loading="lazy"
                                            src="images/golem.png"
                                            style={{
                                              width: "20px",
                                              height: "20px"
                                            }}
                                          />
                                          <br />
                                          <span style={{ marginTop: "3px" }}>
                                            {" "}
                                            GOLEM{" "}
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <br />
                                  <br />
                                </div>
                              )}
                              <span
                                dangerouslySetInnerHTML={this.createMarkupP(
                                  item.data.paragraph
                                )}
                              ></span>
                              {/* <p style={{ textAlign: "justify" }}>
                                                                We provide fully developed crypto exchanges. Timing is everything. Being
                                                                able to operate top
                                                                standard crypto transactions on your own crypto exchange is now possible.
                                                                Getting involved in this industry now will not only allow you to stay ahead
                                                                of the game, but will fortify your position in the market as it starts to
                                                                grow exponentially. By providing turn key crypto
                                                                exchanges, Venturex Labs delivers secure, functional and customized
                                                                platforms focusing on high standard customer experience. Security and
                                                                rapidity are the two key features that underline our solutions, paving the
                                                                way to fast and reliable results.
                                                            </p> */}
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                {/* <div className={`tab-item ${wallets && "curent"}`}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="item bord">
                                                <br />
                                                <p>
                                                    Cryptocurrencies can only exist with crypto wallets that secure private keys
                                            on order to <br />store, send and receive most cryptocurrencies available in
                                            the market. VenturexLabs<br /> makes the creation of customised crypto wallets
                                            simple. The conception process of the <br />wallets makes the process fast,
                                            friendly and customised in order to follow the clients <br />specific needs
                                            and requirements. Our wallets are multi platformed, intuitive and adapt<br />
                                                    to mobiles and desktops. All features have been designed to meet all-level
                                            customers<br /> with efficiency and realistic time-frames. Creating High
                                            standard Wallets has never<br /> been as simple.
                                        </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className={`tab-item ${blockchain && "curent"}`}>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="item bord">
                                                <p>We know how important it is to fully control Blockchain Infrastructure in
                                            private and<br /> public sectors. This is why VenturexLabs has developed a
                                            Blockchain Solution that<br /> brings increased independence to the user terms
                                            of usability, features and custom.<br /> We strive to extend our user’s
                                            freedom for implementing changes in their own built<br />in system, using
                                            Hyperledger and Quorum to do so. Creating a Blockchain is great,<br /> owning
                                            is better.</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className={`tab-item ${quorum && "curent"}`}> 
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="item bord">
                                                <p>Quorum’s Ethereum based Distributed Ledger Technology enables more
                                            efficient<br /> transactional activity in terms of speed and privacy and
                                            operates in a permissioned<br /> network. Smart contracts are used in Quorum
                                            development to allow consensus-driven<br /> modes of operation and automated
                                            mechanisms. These are just a few of the reasons<br /> that lead Quorum
                                            development to be of increasing interest to banks and financial<br />
                                                    institutions. Venturex Labs develops these solutions for entities wishing to
                                            transition<br /> towards the use of Quorum.</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Solutions;
