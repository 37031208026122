import React from "react";

export default ({ width, height, fill, bg }) => {
  return (
    <svg
      style={{ backgroundColor: bg }}
      fill={fill}
      width={width}
      height={height}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 454.44 454.35"
    >
      <g>
        <path
          class="st0"
          d="M118.9,243.36c-10.18-18.94-8.25-34.06,5.71-45.22c11.92-9.53,28.91-9.18,40.49,0.69
		c11.43,9.74,16.78,27.98,4.73,44.52c11.22,6.09,19.67,14.83,24.98,26.47c3.29,7.23,5,14.84,4.82,22.83
		c-0.12,5.49-3.52,8.97-9.03,8.98c-30.86,0.03-61.72,0.03-92.58,0c-5.26,0-8.84-3.57-8.79-8.9c0.2-20.61,9.35-36.27,26.49-47.45
		C116.75,244.61,117.84,244,118.9,243.36z M179.03,283.19c-2.18-12.78-15.91-27.83-36.22-27.5c-16.03,0.26-32.78,14.03-33.77,27.5
		C132.29,283.19,155.55,283.19,179.03,283.19z M144.58,209.62c-7.21-0.09-13.81,6.34-13.98,13.64c-0.17,7.09,6.43,13.85,13.63,13.98
		c7.13,0.13,13.87-6.44,13.98-13.64C158.34,216.44,151.77,209.71,144.58,209.62z"
        />
        <path
          class="st0"
          d="M335.51,243.33c13.02,7.21,22.49,17.58,26.91,31.89c1.7,5.49,2.33,11.4,2.76,17.17
		c0.43,5.82-3.32,9.25-9.21,9.25c-22.05,0.02-44.1,0.01-66.14,0.01c-8.53,0-17.06,0.01-25.59-0.01c-6.01-0.02-9.5-3.47-9.36-9.5
		c0.47-20.34,9.51-35.87,26.53-46.87c0.56-0.36,1.15-0.69,1.73-1.03c0.49-0.29,0.98-0.58,1.48-0.87
		c-12.59-17.7-5.8-36.01,5.42-45.06c11.93-9.62,28.93-9.42,40.73,0.53C341.87,208.22,347.76,226.42,335.51,243.33z M274.55,283.07
		c23.49,0,46.79,0,70.11,0c-1.71-14.01-17.77-26.79-34.22-27.42C293.88,255.02,277.79,267.25,274.55,283.07z M310.1,237.22
		c7.29-0.04,13.76-6.53,13.77-13.81c0.01-7.21-6.59-13.8-13.81-13.79c-7.22,0.02-13.8,6.62-13.78,13.83
		C296.29,230.67,302.89,237.25,310.1,237.22z"
        />
        <path
          class="st0"
          d="M263.9,152.29c-10.12-5.67-20.71-8.32-31.83-8.79c-19.99-0.85-37.92,4.85-53.86,17.04
		c-2.03,1.55-4.77,2.5-7.33,2.94c-4.31,0.75-8.14-1.93-9.9-6.23c-1.47-3.59-0.01-8.15,3.62-10.93c12.1-9.29,25.62-15.6,40.5-18.89
		c21.59-4.77,42.59-2.81,62.96,5.83c0.43,0.18,0.87,0.35,2.03,0.18c-1.36-3.35-2.53-6.8-4.12-10.04c-2.52-5.14-1.1-11.05,3.61-13.82
		c4.85-2.85,10.49-1.32,13.6,3.82c1.08,1.79,2.13,3.69,2.72,5.68c2.39,8.05,4.63,16.15,6.85,24.25c0.63,2.3,1,4.67,1.47,7.01
		c1.19,5.84-0.43,10.58-5.84,13.02c-6.84,3.08-14.06,5.4-21.24,7.62c-4.91,1.52-10.04,2.35-15.1,3.38c-1.48,0.3-3.05,0.31-4.58,0.28
		c-5.05-0.08-8.76-3.18-9.27-7.65c-0.55-4.9,2.75-9.72,7.52-10.79C251.77,154.83,257.84,153.58,263.9,152.29z"
        />
        <path
          class="st0"
          d="M187.04,359.13c1.42,3.48,2.57,7.11,4.33,10.41c1.93,3.63,2.24,7.07,0.28,10.63
		c-1.91,3.46-5.38,3.98-8.85,4.29c-2.52,0.22-4.33-1.13-5.84-3.04c-4.01-5.08-5.98-11.09-7.68-17.17
		c-1.62-5.79-2.83-11.69-4.26-17.54c-0.34-1.38-0.73-2.77-1.29-4.07c-2.29-5.29-1-10.44,4.07-13.07c4.55-2.36,9.38-4.36,14.31-5.75
		c7.81-2.2,15.77-3.86,23.71-5.53c5.17-1.09,10.49,2.59,11.45,7.59c0.87,4.49-2.95,9.81-7.8,10.86c-5.5,1.18-11.01,2.34-17.35,3.69
		c1.47,0.78,2.22,1.27,3.03,1.61c28.24,12.05,55.16,9.74,80.71-7.44c1.83-1.23,3.48-2.71,5.34-3.88c4.43-2.8,9.69-1.79,12.44,2.28
		c3.08,4.56,2.23,9.61-1.94,12.97c-14.19,11.45-30.36,18.43-48.33,21.04c-17.93,2.6-35.27,0.04-52-6.86c-1.2-0.49-2.39-1-3.58-1.5
		C187.52,358.81,187.28,358.97,187.04,359.13z"
        />
      </g>
    </svg>
  );
};
