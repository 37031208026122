import React, { Component } from 'react';
import Navbar from '../Navbar';
import CeoMessage from './CeoMessage';
import Footer from '../footer';

class CeoMessageContainer extends Component{
    render() {
        return (
            <div className='CeoMessageContainer'>                
                <CeoMessage language={this.props.language}/>                
            </div>
        )
    }
}

export default CeoMessageContainer;
