import React, { Component } from "react";
import "./Services.css";
import SvgIcon from "./utils/SvgIcon";

import * as firebase from "firebase";
// import doTranslate from "./helpers/googleTranslateHelper";
class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      selectedService: null,
      languageChangeCount: props.language.languageChangeCount
    };
  }
  componentDidMount = () => {
    const serviceRef = firebase.database().ref("/services");
    serviceRef.on("value", snap => {
      if (snap.val()) {
        let services = this.convertToArray(snap.val());

        this.setState({ services: services }, cb => {
          this.translation();
        });
      }
    });
  };
  convertToArray(Objects) {
    const arrayResult = Object.keys(Objects).map(key => {
      return { id: key, data: Objects[key] };
    });
    return arrayResult;
  }
  componentDidUpdate() {
    if (
      this.state.languageChangeCount != this.props.language.languageChangeCount
    ) {
      this.setState(
        {
          languageChangeCount: this.props.language.languageChangeCount
        },
        cb => {
          this.translation();
        }
      );
    }
  }
  translation() {
    let that = this;
    let services = this.state.services;
    for (let index = 0; index < services.length; index++) {
      if (services[index]) {
        // doTranslate(services[index].data.title, that.props.language.selectedLanguage.language).then(trans => {
        //     services[index].data.title = trans;
        //     this.setState({ services })
        // });
        // doTranslate(
        //   services[index].data.desc,
        //   that.props.language.selectedLanguage.language
        // ).then(trans => {
        //   services[index].data.desc = trans;
        //   this.setState({ services });
        // });
      }
    }
  }
  compressString(str, length) {
    // console.log(str);

    let newStr = "";
    if (str.length > length) {
      newStr = str.slice(0, length);
      // for (let index = 0; index < length + 1; index++) {
      //     newStr += str[index];
      // }
      newStr += " ...";
    } else {
      newStr = str;
    }
    return newStr;
  }
  getServiceColor(rem2, rem4) {
    if (rem4 == 0) {
      return rem2 == 0 ? "#00222E" : "#1F3E52";
    } else if (rem4 == 1) {
      return rem2 == 0 ? "#00222E" : "#1F3E52";
    } else if (rem4 == 2) {
      return rem2 == 0 ? "#00222E" : "#1F3E52";
    } else if (rem4 == 3) {
      return rem2 == 0 ? "#00222E" : "#1F3E52";
    } else if (rem4 == 4) {
      return rem2 == 0 ? "#1F3E52" : "#00222E";
    } else if (rem4 == 5) {
      return rem2 == 0 ? "#1F3E52" : "#00222E";
    } else if (rem4 == 6) {
      return rem2 == 0 ? "#1F3E52" : "#00222E";
    } else if (rem4 == 7) {
      return rem2 == 0 ? "#1F3E52" : "#00222E";
    }
    // else if (rem4 == 5) {
    //   return "#1F3E52";
    // } else if (rem4 == 6) {
    //   return "#00222E";
    // } else if (rem4 == 7) {
    //   return "#1F3E52";
    // }
  }
  render() {
    const { languageKeywords, selectedLanguage } = this.props.language;
    return (
      <section
        id="Industries"
        className="services section-padding pb-70"
        data-scroll-index="3"
      >
        <div className="container">
          <div className="row">
            <div className="section-head col-sm-12">
              <h4>
                <span>Our</span>
                <br />
                {languageKeywords.services[selectedLanguage.language]}
              </h4>
            </div>
            {this.state.services.map((item, key) => {
              return (
                <div className="col-lg-4 col-md-6" key={key}>
                  <div
                    className="item hover_effect my-3"
                    style={{
                      backgroundColor: this.getServiceColor(key % 2, key % 4)
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {item.data.iconImage ? (
                        <img
                          src={item.data.iconImage}
                          style={{
                            color: "#fff",
                            fontSize: "30px",
                            height: "31px",
                            width: "31px"
                          }}
                        />
                      ) : (
                        <i
                          className={item.data.icon}
                          style={{ color: "#fff", fontSize: "30px" }}
                        ></i>
                      )}

                      <span
                        className="Solution_heading"
                        style={{
                          color: "#fff",
                          fontWeight: "500",
                          marginLeft: "10px",
                          paddingTop: "7px"
                        }}
                      >
                        {this.compressString(item.data.title, 12)}
                        {/* key:{key} rem2:{key % 2} rem4:{key % 8} */}
                      </span>
                    </div>
                    <p
                      className="Trun mt-4"
                      style={{ color: "#fff", fontSize: "12px" }}
                    >
                      {this.compressString(item.data.desc, 140)}
                    </p>
                    <a
                      href="#"
                      data-toggle="modal"
                      data-target="#service-modal"
                      className="butn mt-4"
                      style={{
                        color: "#fff",
                        marginTop: "5px",
                        textDecoration: "none"
                      }}
                      onClick={() => {
                        this.setState({ selectedService: item.data });
                      }}
                    >
                      Read More...
                    </a>
                  </div>
                </div>
              );
            })}
          </div>

          <div id="service-modal" className="modal fade" role="dialog">
            <div className="modal-dialog">
              {/* <!-- Modal content--> */}
              {this.state.selectedService && (
                <div className="modal-content  modal_width">
                  <div
                    className="modal-header"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    <h6 style={{ float: "left" }}>
                      {this.state.selectedService.title}
                    </h6>
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i style={{ fontSize: "30px" }} class="fas fa-times"></i>
                    </button>
                  </div>
                  <div
                    className="modal-body row"
                    style={{ textAlign: "justify" }}
                  >
                    <div className="col-sm-4 d-flex justify-content-center align-items-center">
                      {this.state.selectedService.link ? (
                        <a
                          href={this.state.selectedService.link}
                          target="_blank"
                        >
                          <SvgIcon
                            name={this.state.selectedService.modalImage}
                            bg="#1f3d51"
                            fill="white"
                            width="180"
                            height="230"
                          />
                          {/* <img
                            src={this.state.selectedService.modalImage}
                            style={{ width: "100%", height: "140px" }}
                            className="testing-serv"
                          /> */}
                        </a>
                      ) : (
                        <SvgIcon
                          name={this.state.selectedService.modalImage}
                          bg="#1f3d51"
                          fill="white"
                          width="180"
                          height="230"
                        />
                        // <img
                        //   src={this.state.selectedService.modalImage}
                        //   style={{ width: "100%", height: "140px" }}
                        //   className="testing-serv"
                        // />
                      )}
                    </div>
                    <div className="col-sm-8">
                      <p>{this.state.selectedService.desc}</p>
                    </div>
                  </div>
                  {/* <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Services;
