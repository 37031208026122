import React from "react";

export default ({ width, height, fill, bg }) => {
  return (
    <svg
      style={{ backgroundColor: bg }}
      fill={fill}
      width={width}
      height={height}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 454.44 454.35"
    >
      <path
        class="st0"
        d="M364.72,166.06h-53.47c-4.45-12.49-14.29-22.31-26.78-26.75v-53.5h-8.92v51.3c-7.53-1.06-15.2-0.19-22.29,2.53
	V85.81h-8.92v58.48c-2.34,1.57-4.53,3.37-6.53,5.35l-29.13,29.15v-28.72l17.83-17.83V85.81h-8.92v42.74l-15.22,15.22h-49.81
	l-17.83-17.83h-11.4c-2.24-8.7-10.61-14.37-19.51-13.23c-8.91,1.13-15.59,8.71-15.59,17.69c0,8.98,6.68,16.56,15.59,17.69
	c8.91,1.13,17.27-4.54,19.51-13.23h7.7l35.67,35.67h33.05v17.2l-5.1,5.1h-51.02l-26.75-26.75H79.39v8.92h33.82l26.75,26.75h45.79
	l-17.83,17.83h-37.64l-26.75-26.75H79.39v8.92h20.45l17.83,17.83H79.39v8.92H159c0,0-17.02,17.14-17.64,17.83h-26.94
	c-2.24-8.7-10.61-14.37-19.51-13.23c-8.91,1.13-15.59,8.71-15.59,17.69c0,8.98,6.68,16.56,15.59,17.69
	c8.91,1.13,17.27-4.54,19.51-13.23h20.78c-7.38,14.22-6.56,31.31,2.15,44.76v32.55l-31.21,13.38v25.24h8.92v-19.36l31.21-13.38
	v-28.39c6.37,5.29,14.09,8.68,22.29,9.81v16.26c-8.7,2.25-14.37,10.61-13.23,19.51c1.13,8.91,8.71,15.59,17.69,15.59
	c8.98,0,16.56-6.68,17.69-15.59c1.13-8.91-4.54-17.27-13.23-19.51V320.2c7.97-0.47,15.67-3.07,22.29-7.54v58.49h8.92v-66.24
	l8.92-8.92v24.13l35.67,22.29v28.74h8.92v-33.68l-35.67-22.29v-28.09l13.38-13.38v33.09l44.58,26.75v37.59h8.92v-35.67h27.38
	c2.24,8.69,10.6,14.36,19.51,13.23c8.91-1.13,15.58-8.71,15.58-17.69c0-8.98-6.68-16.56-15.58-17.69
	c-8.91-1.13-17.27,4.54-19.51,13.23h-30.61l-41.36-24.81v-36.96l1.62-1.63l7.93-7.93h28.72l31.21,31.21h11.4
	c2.24,8.69,10.6,14.36,19.51,13.23c8.91-1.13,15.58-8.71,15.58-17.69c0-8.98-6.68-16.56-15.58-17.69
	c-8.91-1.13-17.27,4.54-19.51,13.23h-7.7l-22.29-22.29h65.03v-8.92h-97.45l17.83-17.83h36.88l26.75-26.75h15.99v-8.92h-19.68
	l-26.75,26.75h-24.27l6.85-6.85c8.39-8.34,13.09-19.69,13.06-31.52c-0.01-2.08-0.17-4.16-0.47-6.22h51.27V166.06z M106.14,139.31
	c-4.92,0-8.92-3.99-8.92-8.92s3.99-8.92,8.92-8.92s8.92,3.99,8.92,8.92S111.06,139.31,106.14,139.31z M97.22,259.69
	c-4.92,0-8.92-3.99-8.92-8.92s3.99-8.92,8.92-8.92s8.92,3.99,8.92,8.92S102.14,259.69,97.22,259.69z M337.97,322.1
	c4.92,0,8.92,3.99,8.92,8.92s-3.99,8.92-8.92,8.92s-8.92-3.99-8.92-8.92S333.05,322.1,337.97,322.1z M346.89,273.06
	c4.92,0,8.92,3.99,8.92,8.92s-3.99,8.92-8.92,8.92s-8.92-3.99-8.92-8.92S341.96,273.06,346.89,273.06z M170.4,161.6l-8.92-8.92
	h38.28v8.92H170.4z M193.68,256.85c3.22,3.2,6.91,5.89,10.95,7.97l-20.4,20.41c-5.29,5.05-13.62,5.05-18.92,0
	c-5.22-5.22-5.22-13.69,0-18.91l20.37-20.37C187.77,249.97,190.47,253.64,193.68,256.85z M181.93,353.31c0,4.92-3.99,8.92-8.92,8.92
	s-8.92-3.99-8.92-8.92s3.99-8.92,8.92-8.92S181.93,348.39,181.93,353.31z M244.12,256.85l-1.57,1.58l-42.56,42.56
	c-13.93,13.93-36.52,13.93-50.45,0c-13.93-13.93-13.93-36.52,0-50.45l31.58-31.58c-0.32,2.11-0.48,4.23-0.5,6.36
	c0.01,3.89,0.53,7.76,1.54,11.52L159,260c-8.71,8.71-8.71,22.82,0,31.53c8.71,8.71,22.82,8.71,31.53,0l44.14-44.14
	c1.33-1.33,2.49-2.83,3.45-4.46c3.77-6.4,4.1-14.27,0.88-20.96c-3.22-6.7-9.58-11.34-16.94-12.38l10.75-10.76
	c9.41,3.93,16.67,11.73,19.92,21.4c4.32,12.84,1,27.03-8.58,36.61L244.12,256.85z M215.75,234.78c-4.07-3.98-5.08-10.15-2.5-15.22
	c5.1-2.48,11.22-1.44,15.2,2.6c3.99,4.04,4.95,10.17,2.41,15.24C225.77,239.8,219.73,238.75,215.75,234.78z M250.43,200.1
	c-3.22-3.2-6.91-5.89-10.95-7.97l20.41-20.4c5.29-5.05,13.62-5.05,18.92,0c5.22,5.22,5.22,13.69,0,18.92l-20.41,20.4
	C256.31,207.01,253.63,203.32,250.43,200.1z M294.56,206.41l-31.58,31.58c0.32-2.11,0.48-4.23,0.5-6.36
	c-0.01-3.89-0.53-7.77-1.54-11.52l23.16-23.15c8.64-8.72,8.61-22.78-0.07-31.45c-8.68-8.68-22.74-8.71-31.46-0.07l-44.14,44.14
	c-8.71,8.71-8.71,22.82,0,31.52c3.43,3.41,7.88,5.6,12.67,6.24l-10.81,10.81c-10.93-4.61-18.85-14.36-21.12-26
	c-2.27-11.64,1.4-23.65,9.79-32.03l44.14-44.14c13.93-13.93,36.51-13.93,50.44,0C308.49,169.9,308.49,192.48,294.56,206.41z"
      />
    </svg>
  );
};
