import React, { Component } from 'react';
import PortfolioPage from './PortfolioPage';
import PortfolioImg from '../images/Portfolio-3.jpg';
import Header from './Header';
import Footer from '../footer';
import Navbar from '../Navbar';

class PortfolioContainer extends Component {
    render() {
        return (
            <div className="PortfolioContainer">
                <Header title={"Portfolio"} IMG={PortfolioImg} />
                <PortfolioPage />
            </div>
        );
    }
}

export default PortfolioContainer;
