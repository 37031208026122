import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { HashLink } from "react-router-hash-link";
import logo1 from "./images/logo-light.png";
import "./Navbar.css";
import $ from "jquery";

import * as firebase from "firebase";

class Navbar extends Component {
  state = {
    language: null,
    clicked: false,
    navbarToggle: false,
    hover: false
  };
  componentDidMount = () => {
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        this.setState({ navbarToggle: true });
      } else {
        this.setState({ navbarToggle: false });
      }
    });
    window.scrollTo(0, 0);
  };
  toggleClass = () => {
    $("#navbarSupportedContent").removeClass("show");
  };
  enterMouse = () => {
    this.setState({ hover: true });
  };
  leaveMouse = () => {
    this.setState({ hover: false });
  };
  render() {
    return (
      <nav
        onMouseEnter={this.enterMouse}
        onMouseLeave={this.leaveMouse}
        style={{ zIndex: "100" }}
        className={`navbar navbar-expand-lg alpha ${this.state.navbarToggle &&
          "nav-scroll"}`}
      >
        {this.props.languageKeywords && (
          <div className="container-fluid">
            <Link to="/">
              <div id={this.state.hover && "vxlogo"} className="logo1"></div>
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar">
                <i className="fas fa-bars"></i>
              </span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto pull-right">
                <li className="nav-item">
                  <NavLink
                    onClick={this.toggleClass}
                    exact
                    to="/"
                    className="nav-link"
                    activeClassName="active"
                  >
                    {
                      this.props.languageKeywords.home[
                        this.props.selectedLanguage.language
                      ]
                    }
                  </NavLink>
                </li>
                <li className="nav-item">
                  {/* <ScrollLink
                    to="about"
                    spy={true}
                    hashSpy={true}
                    offset={0}
                    smooth={true}
                    duration={500}
                    style={{ cursor: "pointer" }}
                    className="nav-link scrolling"
                  > */}
                  <HashLink
                    onClick={this.toggleClass}
                    style={{ cursor: "pointer" }}
                    className="nav-link scrolling"
                    to="/#about"
                  >
                    {
                      this.props.languageKeywords.about[
                        this.props.selectedLanguage.language
                      ]
                    }
                  </HashLink>
                  {/* </ScrollLink> */}
                </li>
                <li className="nav-item hover_nav">
                  <NavLink
                    onClick={this.toggleClass}
                    exact
                    to="/industries"
                    className="nav-link hover_nav "
                    activeClassName="active"
                  >
                    {
                      this.props.languageKeywords.industries[
                        this.props.selectedLanguage.language
                      ]
                    }
                  </NavLink>
                </li>

                <li className="nav-item ">
                  <NavLink
                    onClick={this.toggleClass}
                    to="/services"
                    className="nav-link hover_nav "
                    activeClassName="active"
                  >
                    {
                      this.props.languageKeywords.services[
                        this.props.selectedLanguage.language
                      ]
                    }
                  </NavLink>
                </li>

                <li className="nav-item ">
                  <NavLink
                    onClick={this.toggleClass}
                    to="/portfolio"
                    className="nav-link hover_nav "
                    activeClassName="active"
                  >
                    {
                      this.props.languageKeywords.portfolio[
                        this.props.selectedLanguage.language
                      ]
                    }
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink
                    onClick={this.toggleClass}
                    to="/solutions"
                    className="nav-link hover_nav"
                    activeClassName="active"
                  >
                    {
                      this.props.languageKeywords.solutions[
                        this.props.selectedLanguage.language
                      ]
                    }
                  </NavLink>
                </li>
                <li className="nav-item ">
                  <NavLink
                    onClick={this.toggleClass}
                    to="/usecase"
                    className="nav-link hover_nav"
                    activeClassName="active"
                  >
                    {
                      this.props.languageKeywords.case_study[
                        this.props.selectedLanguage.language
                      ]
                    }
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    onClick={this.toggleClass}
                    to="/blogs"
                    className="nav-link hover_nav "
                    activeClassName="active"
                  >
                    {
                      this.props.languageKeywords.blog[
                        this.props.selectedLanguage.language
                      ]
                    }
                  </NavLink>
                </li>

                <li className="nav-item">
                  {/* <ScrollLink
                    to="contactUs"
                    spy={true}
                    hashSpy={true}
                    offset={20}
                    duration={500}
                    style={{ cursor: "pointer" }}
                    className="nav-link hover_nav scrolling"
                  > */}
                  <HashLink
                    onClick={this.toggleClass}
                    style={{ cursor: "pointer" }}
                    className="nav-link hover-nav scrolling"
                    to="/#contact"
                  >
                    {
                      this.props.languageKeywords.contact[
                        this.props.selectedLanguage.language
                      ]
                    }
                  </HashLink>
                  {/* </ScrollLink> */}
                </li>

                <li className="nav-item kd" style={{ marginTop: "-3px" }}>
                  <div className="dropdown">
                    <button className="dropbtn">
                      <img
                        src={this.props.selectedLanguage.flag}
                        alt=""
                        style={{ height: "15px", width: "20px" }}
                      />
                      <span>
                        &nbsp;&nbsp;{this.props.selectedLanguage.name}
                      </span>
                      <span className="caret"></span>
                    </button>
                    <div className="dropdown-content">
                      <ol>
                        {this.props.languageCodes.map((code, key) => {
                          return (
                            <li
                              onClick={() => {
                                this.props.onChangeLanguage(code);
                                this.toggleClass();
                              }}
                              key={key}
                            >
                              <a id={code.name}>
                                <span
                                  style={{ color: "gray", cursor: "pointer" }}
                                >
                                  {code.name}&nbsp;
                                </span>
                                <img
                                  src={code.flag}
                                  alt=""
                                  style={{ height: "15px", width: "20px" }}
                                />
                              </a>
                            </li>
                          );
                        })}

                        {/* <li>
                                                <a id="English"
                                                    style={{ textDecoration: "none" }}>
                                                    <span style={{ color: "gray", cursor: "pointer" }}></span>
                                                    <span style={{ textAlign: "left", color: "gray", cursor: "pointer" }}>English&nbsp;&nbsp;</span><img
                                                        src="images/flags/uk.jpg" alt="" style={{ height: "15px", width: "20px" }} />
                                                </a>
                                            </li>
                                            <li>
                                                <a id="French">
                                                    <span style={{ color: "gray", cursor: "pointer" }}>French&nbsp;&nbsp;&nbsp;</span><img src="images/flags/french.png"
                                                        alt="" style={{ height: "15px", width: "20px" }} />
                                                </a>
                                            </li>
                                            <li>
                                                <a id="German">
                                                    <span style={{ color: "gray", cursor: "pointer" }}>German&nbsp;&nbsp;</span><img src="images/flags/germany.png" alt=""
                                                        style={{ height: "15px", width: "20px" }} />
                                                </a>
                                            </li>
                                            <li>
                                                <a id="Spanish">
                                                    <span style={{ color: "gray", cursor: "pointer" }}>Spanish&nbsp;</span><img src="images/flags/spain.png" alt=""
                                                        style={{ height: "15px", width: "20px" }} />
                                                </a>
                                            </li> */}
                      </ol>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )}
      </nav>
    );
  }
}

export default Navbar;
