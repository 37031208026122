import React, { Component } from 'react';
import './PortfolioPage.css';
import cryptoImg from '../images/react-portfolio/crypto.png';
import vencharImg from '../images/react-portfolio/venchar.png';
import vxstack from '../images/react-portfolio/vxstack.jpg';
import * as firebase from 'firebase';



class PortfolioPage extends Component{
    state={
        portfolio:[]
    };
    componentDidMount=()=>{
        const portfolioRef=firebase.database().ref('/portfolio');
        portfolioRef.on('value', snap=>{
            if(snap.val()){
                this.setState({
                    portfolio: this.convertToArray(snap.val())
                })
            }
        })

    }
    convertToArray(Objects) {
        const arrayResult = Object.keys(Objects).map(key => {
            return { id: key, data: Objects[key] }
        });
        return arrayResult;
    }
    render(){
        const Portfolio = this.state.portfolio.map(val =>(
            <div className="col-md-4 items CryptocurrencyExchanges" key={val.id}>
            <div className="item-img">
                <img src={val.data.image} style={{height:"230px"}} alt="image"
                    className="images_size" />
                <a href={val.data.link} target="_blank">
                    <div className="item-img-overlay ">

                    </div>
                </a>
            </div>
        </div>

        ))
        return (
            <section id="portfolio" className="portfolio section-padding" data-scroll-index="2">
            <div className="container">
                <div className="row">
    
                    <div className="section-head col-sm-12">
                        <h4>
                            <span>Our</span><br/>
                            Portfolio
                        </h4>
                    </div>
    
                      {Portfolio}
                        <div className="clear-fix"></div>
    
                    </div>
            </div>
        </section>
        );
    }
}

export default PortfolioPage;