import React, { Component } from 'react';
import './Header.css';

class Header extends Component{
    render() {
        return (
            <section id="home" className="bg-img" data-scroll-index="0" data-overlay-dark="5" data-stellar-background-ratio="0.5" style={{backgroundImage: `url(${this.props.IMG})`,height: "50vh"}}>
        <div className="container-fluid">
            <div className="row">
                <div className="v-middle mt-30">
                    <div className="text-center col-md-12">
                      <h5>{this.props.title}</h5>
                    </div>
                </div>
            </div>
        </div>
    </section>
        );
    }
}

export default Header;