import React, { Component } from 'react';
import './Insurance.css';


class Insurance extends Component{
    render() {
        return (
            <div className='Insurance'>
                 <div className="tab-pane fade in active show">
                    <div className="row" style={{marginTop:"20px"}}>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="card">
                                <div className="item-img">
                                    <img src="images/20.jpg" alt="image" />
                                    <a href="images/20.jpg">
                                        <div className="item-img-overlay valign">
                                            <div className="overlay-info full-width vertical-center">
                                                <h6 style={{textAlign:"center"}}>Insurance</h6>
                                                <p style={{textAlign:"center"}}>http://www.venturexlabs.com/ </p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="card">
                                <div className="item-img">
                                    <img src="images/22.jpg" alt="image" />
                                    <a href="images/22.jpg">
                                        <div className="item-img-overlay valign">
                                            <div className="overlay-info full-width vertical-center">
                                                <h6 style={{textAlign:"center"}}>Insurance</h6>
                                                <p style={{textAlign:"center"}}>http://www.venturexlabs.com/ </p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="card">
                                <div className="item-img">
                                    <img src="images/21.jpg" alt="image" />
                                    <a href="images/21.jpg">
                                        <div className="item-img-overlay valign">
                                            <div className="overlay-info full-width vertical-center">
                                                <h6 style={{textAlign:"center"}}>Insurance</h6>
                                                <p style={{textAlign:"center"}}>http://www.venturexlabs.com/ </p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-md-12" style={{height:"20px"}}></div>
              
                    </div>
                </div>
            </div>
        );
    }
}

export default Insurance;