import React, { Component } from 'react';
import ICOPage from './ICOPage';
import Exchange from './Exchange';
import Insurance from './Insurance';

class UsecasePage extends Component {
    state = {
        ico: true,
        exchange: false,
        insurance: false
    }
    activeIco = () => {
        this.setState({ ico: true, exchange: false, insurance: false });
    }
    activeExchange = () => {
        this.setState({ ico: false, exchange: true, insurance: false });
    }
    activeInsurance = () => {
        this.setState({ ico: false, exchange: false, insurance: true });
    }
    render() {
        const { ico, exchange, insurance } = this.state;
        return (
            <section id="portfolio" className="portfolio section-padding" data-scroll-index="2">
                <div className="container">
                    <div className="row">

                        <div className="section-head col-sm-12">
                            <h4>
                                <span></span><br />
                                Use Cases
                            </h4>
                        </div>

                        <ul class="nav nav-tabs col-md-12 col-sm-12 col-xs-12">
                            <li onClick={this.activeIco} className="col-md-2 col-sm-4 col-xs-12 cls-menu portfolio filtering" style={{ fontSize: "14px", fontWeight: "600", textTransform: "uppercase", cursor: "pointer", color: ico && "#940316" }}>ICO-STO</li>
                            <li onClick={this.activeExchange} className="col-md-2 col-sm-4 col-xs-12 cls-menu portfolio filtering" style={{ fontSize: "14px", fontWeight: "600", textTransform: "uppercase", cursor: "pointer", color: exchange && "#940316" }}>Exchange</li>
                            <li onClick={this.activeInsurance} className="col-md-2 col-sm-4 col-xs-12 cls-menu portfolio filtering" style={{ fontSize: "14px", fontWeight: "600", textTransform: "uppercase", cursor: "pointer", color: insurance && "#940316" }}>Insurance</li>

                        </ul>

                        <div className="tab-content">
                            {ico && <ICOPage />}
                            {exchange && <Exchange />}
                            {insurance && <Insurance />}
                        </div>
                        <div className="clear-fix"></div>
                    </div>
                </div>
            </section>
        );
    }
}

export default UsecasePage;


