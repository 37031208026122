export default function GetDate(date) {
  const givenDate = new Date(date);
  const month = givenDate.getMonth();
  const day = givenDate.getUTCDate();
  const year = givenDate.getUTCFullYear();
  const hour = givenDate.getHours();
  const min = givenDate.getUTCMinutes();

  return `${day < 10 ? `0${day}` : day}/${
    month < 10 ? `0${month}` : month
  }/${year}  ${hour % 12 === 0 ? 12 : hour % 12}:${min} ${
    hour > 12 ? "PM" : "AM"
  }`;
}
