import React, { Component } from "react";
import Navbar from "./Navbar";
import MainHeader from "./MainHeader";
import Footer from "./footer";
import Hero from "./Hero";
import Services from "./Services";
import Solutions from "./Solutions";
import Blogs from "./Blogs";
import Clients from "./Clients";
import Map from "./Map";
import Contact from "./Contact";
import PortfolioPage from "./Pages/PortfolioPage";

class MainPage extends Component {
  render() {
    return (
      <div className="MainPage">
        <MainHeader language={this.props.language}/>
        <Hero language={this.props.language}/>
        <Services language={this.props.language}/>
        <Solutions language={this.props.language} />
        <PortfolioPage language={this.props.language}/>
        <Blogs language={this.props.language}/>
        <Clients language={this.props.language}/>
        <Map language={this.props.language}/>
        <Contact language={this.props.language}/>
      </div>
    );
  }
}

export default MainPage;
