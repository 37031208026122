import React, { Component } from "react";
import Header from "./Header";
import Navbar from "../Navbar";
import IMG from "../images/2.jpg";
import BlogDetail from "./BlogDetail";
import Footer from "../footer";

class BlogsDetailContainer extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };
  render() {
    return (
      <div className="BlogsContainer">
        <Header
          title={
            this.props.language.languageKeywords.blog[
              this.props.language.selectedLanguage.language
            ]
          }
          IMG={IMG}
        />
        <BlogDetail language={this.props.language} {...this.props} />
      </div>
    );
  }
}

export default BlogsDetailContainer;
