import React, { Component } from "react";
import * as firebase from "firebase";
import "./BlogPage.css";
import RecentPosts from "./RecentPosts";

import { Route, Redirect, Link } from "react-router-dom";
// import doTranslate from "../helpers/googleTranslateHelper";

class BlogPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: [],
      languageChangeCount: props.language.languageChangeCount
    };
  }

  componentDidMount = () => {
    const blogsRef = firebase.database().ref("/blogs");
    blogsRef.on("value", snap => {
      if (snap.val()) {
        this.setState(
          {
            blogs: this.convertToArray(snap.val())
          },
          cb => {
            this.translation();
          }
        );
      }
    });
  };
  componentDidUpdate() {
    if (
      this.state.languageChangeCount != this.props.language.languageChangeCount
    ) {
      console.log("Language Change Detected");
      this.setState(
        {
          languageChangeCount: this.props.language.languageChangeCount
        },
        cb => {
          this.translation();
        }
      );
    }
  }
  convertToArray(Objects) {
    const arrayResult = Object.keys(Objects).map(key => {
      return { id: key, data: Objects[key] };
    });
    return arrayResult;
  }
  translation() {
    let that = this;
    let blogs = this.state.blogs;
    for (let index = 0; index < blogs.length; index++) {
      if (blogs[index]) {
        // doTranslate(
        //   blogs[index].data.title,
        //   that.props.language.selectedLanguage.language
        // ).then(trans => {
        //   blogs[index].data.title = trans;

        //   this.setState({ blogs });
        // });
        // doTranslate(
        //   blogs[index].data.detail,
        //   that.props.language.selectedLanguage.language
        // ).then(trans => {
        //   blogs[index].data.detail = trans;

        //   this.setState({ blogs });
        // });
      }
    }
  }
  render() {
    const { languageKeywords, selectedLanguage } = this.props.language;
    const Blogs = this.state.blogs.map(val => (
      <div className="post" key={val.id}>
        <div className="post-img">
          <a href="#0" className="full-width">
            <img src={val.data.image} alt="image" />
          </a>
        </div>
        <div className="content text-left">
          <div className="post-meta">
            <div className="post-title">
              <h5>
                <a href="#0">{val.data.title}</a>
              </h5>
            </div>
            <ul className="meta" style={{ marginBottom: "4px" }}>
              <li>
                <a href="#0">
                  <i className="fa fa-user mr-1" aria-hidden="true"></i>
                  {val.data.user}
                </a>
              </li>
              <li>
                <a href="#0">
                  <i className="fa fa-calendar  mr-1" aria-hidden="true"></i>
                  {val.data.date}
                </a>
              </li>
            </ul>
          </div>

          <div className="post-cont">
            <p className="Trunca">{val.data.detail.slice(0, 100)}</p>
          </div>

          {/* <Redirect to="/post" className="butn">
            Read More
          </Redirect> */}
          <Link to={`/blogs/${val.id}`} className="butn">
            {languageKeywords.read_more[selectedLanguage.language]}
          </Link>
        </div>
      </div>
    ));
    return (
      <section className="blogs section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="posts">
                {Blogs}
                <div className="pagination">
                  <ul>
                    <li>
                      <a href="#0">First</a>
                    </li>
                    <li>
                      <a href="#0">1</a>
                    </li>
                    <li>
                      <a href="#0">2</a>
                    </li>
                    <li>
                      <a href="#0">last</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <RecentPosts language={this.props.language} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BlogPage;
