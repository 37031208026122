import React from "react";

export default ({ width, height, fill, bg }) => {
  return (
    <svg
      style={{ backgroundColor: bg }}
      fill={fill}
      width={width}
      height={height}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 454.44 454.35"
    >
      <g>
        <path
          class="st0"
          d="M114.65,213.59c2.58-11.18,4.6-23.29,8.3-34.87c2.77-8.67,6.98-17.11,11.87-24.81
		c6.41-10.09,15.75-16.46,28.27-17.58c2.31-0.21,5.23-2.65,6.35-4.83c1.74-3.4,2.23-7.46,3.15-11.26c0.57-2.34,0.75-4.79,1.43-7.09
		c2.5-8.43,4.81-10.14,13.55-10.14c25.9,0,51.81,0.16,77.71-0.06c5.6-0.05,9.16,2.32,10.88,7.07c1.52,4.19,2.59,8.74,2.79,13.18
		c0.42,9.33,6.08,13.43,13.51,13.63c12.11,0.32,21.42,6.81,28.57,16.31c9.95,13.21,13.77,28.73,16.29,44.69
		c0.17,1.04,0.3,2.1,0.42,3.15c0.5,4.36-0.28,9.54,1.85,12.84c1.95,3.02,7.3,3.62,10.71,5.94c11.85,8.08,18.53,19.25,18.87,33.79
		c0.18,7.87-0.3,15.77,0.11,23.63c0.66,12.79-5.56,21.95-14.91,29.47c-2.38,1.91-3.43,3.67-3.32,6.7c0.22,6.35,0.3,12.73,0.01,19.08
		c-0.52,11.57-8.38,18.9-19.96,18.96c-5.76,0.03-11.51,0.09-17.27,0c-15.42-0.23-24.62-10.36-23.62-25.85
		c0.15-2.36,0.02-4.74,0.02-7.57c-1.92,0-3.4,0-4.87,0c-38.63,0-77.26,0.07-115.89-0.1c-4.18-0.02-5.65,1.09-5.35,5.3
		c0.27,3.75,0.16,7.59-0.3,11.33c-1.24,10.12-9.04,16.84-19.16,16.85c-5.76,0.01-11.53-0.26-17.27,0.07
		c-7.92,0.46-13.97-3.23-19.53-8.12c-3.09-2.71-4.8-6.2-4.41-10.47c0.01-0.15-0.01-0.3,0.01-0.45c1.5-12.46-0.5-23.38-10.35-32.7
		c-6.22-5.88-8.44-14.3-7.92-23.37c0.57-9.81-0.27-19.71,0.25-29.53c0.57-10.7,4.71-20.25,13.64-26.52
		C103.59,217.04,109.41,215.74,114.65,213.59z M158.45,215.53c44.98,0,89.64,0,134.83,0c-3.01-11.23-5.96-22.21-8.88-33.09
		c-40.23,0-79.9,0-120.01,0C162.41,193.44,160.45,204.38,158.45,215.53z M164.39,260.27c-0.07-9.71-8.33-17.92-18.02-17.93
		c-9.85-0.01-18.28,8.56-18.06,18.36c0.23,9.72,8.62,17.86,18.25,17.71C156.28,278.26,164.46,269.93,164.39,260.27z M332.56,260.34
		c-0.02-9.69-8.25-17.94-17.95-18c-9.67-0.06-17.99,8.12-18.12,17.84c-0.14,9.81,8.36,18.33,18.18,18.23
		C324.37,278.3,332.58,270.01,332.56,260.34z"
        />
      </g>
    </svg>
  );
};
