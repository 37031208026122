import React, { Component } from 'react';
import Header from './Header';
import Navbar from '../Navbar';
import IMG from '../images/2.jpg';
import BlogPage from './BlogPage';
import Footer from '../footer';

class BlogsContainer extends Component{
    render() {
        return (
            <div className="BlogsContainer">
                <Header title={this.props.language.languageKeywords.blog[this.props.language.selectedLanguage.language]} IMG={IMG} />
                <BlogPage language={this.props.language}/>
                
            </div>
        )
    }
}

export default BlogsContainer;
