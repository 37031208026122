import React from "react";

export default ({ width, height, fill, bg }) => {
  return (
    <svg
      style={{ backgroundColor: bg }}
      width={width}
      height={height}
      fill={fill}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 454.44 454.35"
    >
      <g>
        <path
          class="st0"
          d="M235.06,140.89h-21.4v46.21h-19.83l41.23,41.61V140.89z M226.88,208.81l-13.4-13.52h8.38v-46.21h5.02
		L226.88,208.81L226.88,208.81z"
        />
        <path
          class="st0"
          d="M282.34,182.55l-41.23-41.61v87.83h21.4v-46.21H282.34z M254.32,174.36v46.21h-5.02v-59.74l13.4,13.52H254.32z
		"
        />
        <path
          class="st0"
          d="M326.72,168.54c-1.73-37.02-32.39-66.61-69.83-66.61c-28.57,0-53.07,17.12-63.9,41.51
		c-18.68-7.24-38.92,5.26-41.24,24.78c-30.24,3.76-53.42,29.67-53.42,60.53c0,33.64,27.37,61.01,61.01,61.01h5.57v-8.19h-5.57
		c-29.12,0-52.82-23.69-52.82-52.82c0-27.77,21.69-50.92,49.38-52.7l3.86-0.25c0-0.12-0.02-3.89-0.03-4.01
		c0.03-12.26,10.01-22.22,22.27-22.22c3.9,0,7.74,1.03,11.11,2.97l4.2,2.43l1.68-4.55c8.92-24.1,32.19-40.3,57.9-40.3
		c34.03,0,61.72,27.69,61.72,61.72l-0.01,3.99l3.69,0.38c27.01,2.76,47.37,25.35,47.37,52.54c0,29.12-23.69,52.82-52.82,52.82h-5.57
		v8.19h5.57c33.64,0,61.01-27.37,61.01-61.01C377.85,198.77,356.06,173.32,326.72,168.54z"
        />
        <path
          class="st0"
          d="M293,215.06c-5.68-1.74-13.72-3.19-23.24-4.19l-0.86,8.14c16,1.68,23.49,4.17,26.31,5.67
		c-5.31,2.86-24.69,7.14-57.12,7.14c-32.41,0-51.79-4.27-57.11-7.13c3.12-1.64,11.88-4.48,30.76-6.11l-0.7-8.16
		c-10.74,0.92-19.87,2.38-26.42,4.21c-5.53,1.55-12.88,4.27-12.88,10.03v36.88c0,3.1,2.05,5.61,6.41,7.7
		c-4.37,2.08-6.41,4.6-6.41,7.7v36.88c0,3.1,2.05,5.61,6.41,7.7c-4.37,2.09-6.41,4.6-6.41,7.7v36.88c0,5.59,6.66,9.28,21.6,11.98
		c24.81,4.48,64.69,4.48,89.51,0c14.94-2.7,21.6-6.39,21.6-11.98v-36.88c0-3.1-2.04-5.61-6.41-7.7c4.37-2.09,6.41-4.6,6.41-7.7
		v-36.88c0-3.1-2.04-5.62-6.41-7.7c4.37-2.09,6.41-4.6,6.41-7.7v-36.88C304.44,219.21,297.91,216.57,293,215.06z M296.26,233.14
		v27.74c-3.17,2.8-23,7.82-58.17,7.82c-35.17,0-55-5.03-58.17-7.82v-27.74C203.31,242.48,273.9,242.07,296.26,233.14z
		 M179.92,285.42c23.41,9.34,94.01,8.91,116.34,0v27.73c-3.17,2.8-23,7.82-58.17,7.82c-35.17,0-55-5.03-58.17-7.82L179.92,285.42
		L179.92,285.42z M238.09,373.26c-35.17,0-55-5.03-58.17-7.82V337.7c23.39,9.34,93.98,8.92,116.34,0v27.74
		C293.08,368.23,273.26,373.26,238.09,373.26z M295.19,329.25c-5.34,2.86-24.72,7.12-57.1,7.12c-32.38,0-51.76-4.26-57.1-7.12
		c1.59-0.86,4.94-2.21,11.73-3.56c25.15,4.66,65.89,4.61,90.75,0C290.25,327.05,293.6,328.4,295.19,329.25z M295.19,276.98
		c-5.34,2.86-24.72,7.12-57.1,7.12c-32.38,0-51.76-4.26-57.1-7.12c1.59-0.86,4.94-2.21,11.73-3.56c24.87,4.61,65.18,4.62,90.13,0.11
		c0.21-0.04,0.42-0.07,0.62-0.11C290.25,274.77,293.6,276.12,295.19,276.98z"
        />
        <path
          class="st0"
          d="M316.84,276.1v-8.19c12.42,0,24.24-6.01,31.62-16.08l6.61,4.84C346.15,268.84,331.87,276.1,316.84,276.1z
		 M358.28,251.65l-7.16-3.97c1.2-2.16,2.19-4.44,2.96-6.78l7.78,2.55C360.94,246.27,359.73,249.03,358.28,251.65z M363.36,237.65
		l-8.04-1.54c0.46-2.41,0.7-4.89,0.7-7.37h8.19C364.2,231.74,363.92,234.73,363.36,237.65z"
        />
        <path
          class="st0"
          d="M238.09,263.26v-8.19c2.71,0,5.43-0.03,8.09-0.09l0.19,8.19C243.64,263.23,240.86,263.26,238.09,263.26z
		 M251.9,262.99l-0.33-8.18c2.73-0.11,5.44-0.26,8.07-0.43l0.55,8.17C257.49,262.73,254.7,262.88,251.9,262.99z M265.72,262.12
		l-0.72-8.16c9.58-0.84,17.76-2.13,23.66-3.7l2.12,7.91C284.42,259.87,275.76,261.23,265.72,262.12z"
        />
        <path
          class="st0"
          d="M238.09,315.52v-8.19c2.71,0,5.43-0.03,8.09-0.09l0.19,8.19C243.64,315.49,240.86,315.52,238.09,315.52z
		 M251.9,315.25l-0.33-8.18c2.73-0.11,5.44-0.26,8.07-0.43l0.55,8.17C257.49,314.98,254.7,315.13,251.9,315.25z M265.72,314.37
		l-0.72-8.16c9.58-0.84,17.76-2.13,23.66-3.7l2.12,7.91C284.42,312.12,275.76,313.49,265.72,314.37z"
        />
        <path
          class="st0"
          d="M238.09,367.82v-8.19c2.71,0,5.43-0.03,8.09-0.1l0.19,8.19C243.64,367.78,240.86,367.82,238.09,367.82z
		 M251.9,367.54l-0.33-8.18c2.73-0.11,5.44-0.26,8.07-0.43l0.55,8.17C257.49,367.28,254.7,367.43,251.9,367.54z M265.72,366.67
		l-0.72-8.16c9.58-0.84,17.76-2.13,23.66-3.7l2.11,7.91C284.42,364.42,275.76,365.78,265.72,366.67z"
        />
      </g>
    </svg>
  );
};
