import React, { Component } from 'react';
import Header from './Header';
import SolutionImg from '../images/solution.jpg';
import Footer from '../footer';
import SolutionPage from './SolutionPage';
import Navbar from '../Navbar';

class SolutionContainer extends Component {
    render() {
        return (
            <div className='SolutionContainer'>
                <Header title={"Solutions"} IMG={SolutionImg} />
                <SolutionPage />

            </div>
        )
    }
}

export default SolutionContainer;
