import React, { Component } from "react";
import MainPage from "./MainPage";
import IndustriesContainer from "./Pages/IndustriesContainer";
import ServicesContainer from "./Pages/ServicesContainer";
import PortfolioContainer from "./Pages/PortfolioContainer";
import SolutionsContainer from "./Pages/SolutionContainer";
import UsecaseContainer from "./Pages/UsecaseContainer";
import { Switch, Route } from "react-router-dom";
import "./App.css";
import * as firebase from "firebase";
import firebaseConfig from "./firebase/firebaseConfig";
import BlogsContainer from "./Pages/BlogsContainer";
import BlogDetail from "./Pages/BlogDetail";
import CeoMessageContainer from "./Pages/CeoMessageContainer";
import cookie from "react-cookies";

// import { googleTranslate } from "./utils/googleTranslate";
import Navbar from "./Navbar";
import Footer from "./footer";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import BlogsDetailContainer from "./Pages/BlogDetailContainer";
import Sticky from "react-sticky-el";
firebase.initializeApp(firebaseConfig);

class App extends Component {
  state = {
    blogs: [],
    languageCodes: [
      { language: "en", name: "English", flag: "/images/flags/uk.jpg" },
      { language: "fr", name: "French", flag: "/images/flags/french.png" }
      // { language: "de", name: "German", flag: "images/flags/germany.png" },
      // { language: "es", name: "Spanish", flag: "images/flags/spain.png" }
    ],
    selectedLanguage: {
      language: "en",
      name: "English",
      flag: "/images/flags/uk.jpg"
    },
    languageKeywords: null,
    languageChangeCount: 0
  };
  constructor(props) {
    super(props);
    this.state["selectedLanguage"] = this.getDefaultLanguage();
  }
  changeLanguage = language => {
    localStorage.setItem("language", JSON.stringify(language));
    let count = this.state.languageChangeCount + 1;

    this.setState({
      selectedLanguage: language,
      languageChangeCount: count
    });
  };

  getDefaultLanguage() {
    let language = localStorage.getItem("language");
    if (!language) {
      language = {
        language: "en",
        name: "English",
        flag: "/images/flags/uk.jpg"
      };
    } else {
      language = JSON.parse(language);
    }
    return language;
  }
  componentDidMount = () => {
    const languageRef = firebase.database().ref("/language");
    languageRef.on("value", snap => {
      if (snap.val()) {
        this.setState({
          languageKeywords: snap.val()
        });
      }
    });

    const blogsRef = firebase.database().ref("/blogs");
    blogsRef.on("value", snap => {
      if (snap.val()) {
        this.setState({
          blogs: this.convertToArray(snap.val())
        });
      }
    });
  };
  convertToArray(Objects) {
    const arrayResult = Object.keys(Objects).map(key => {
      return { id: key, data: Objects[key] };
    });
    return arrayResult;
  }
  wrapLanguage() {
    return {
      languageKeywords: this.state.languageKeywords,
      selectedLanguage: this.state.selectedLanguage,
      languageChangeCount: this.state.languageChangeCount
    };
  }
  render() {
    const { languageCodes, language, question } = this.state;
    return (
      <div className="App" id="app_el">
        {this.state.languageKeywords ? (
          <div>
            <Navbar
              languageKeywords={this.state.languageKeywords}
              languageCodes={this.state.languageCodes}
              selectedLanguage={this.state.selectedLanguage}
              onChangeLanguage={this.changeLanguage}
            />
            <Switch>
              <Route exact path="/">
                <MainPage language={this.wrapLanguage()} />
              </Route>
              <Route exact path="/ceomessage">
                <CeoMessageContainer language={this.wrapLanguage()} />
              </Route>
              <Route exact path="/industries">
                <IndustriesContainer language={this.wrapLanguage()} />
              </Route>
              <Route exact path="/services">
                <ServicesContainer language={this.wrapLanguage()} />
              </Route>
              <Route exact path="/portfolio">
                <PortfolioContainer language={this.wrapLanguage()} />
              </Route>
              <Route exact path="/solutions">
                <SolutionsContainer language={this.wrapLanguage()} />
              </Route>
              <Route exact path="/usecase">
                <UsecaseContainer language={this.wrapLanguage()} />
              </Route>
              <Route exact path="/blogs">
                <BlogsContainer language={this.wrapLanguage()} />
              </Route>
              <Route
                exact
                path="/blogs/:id"
                render={routProps => (
                  <BlogsDetailContainer
                    {...routProps}
                    language={this.wrapLanguage()}
                  />
                )}
              />
              <Route>
                <MainPage language={this.wrapLanguage()} />
              </Route>
            </Switch>
            <Footer language={this.wrapLanguage()} />
          </div>
        ) : (
          // <span>Loading...</span>
          <Loader
            style={{
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
            type="BallTriangle"
            color="#A30505"
            height={100}
            width={100}
          />
        )}
      </div>
    );
  }
}

export default App;
